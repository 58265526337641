@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');

*, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --ligthmain: #fceaea;
    --maincolor:  #9F0027;
    --monolithgreen: #006834;
    --monolithlightgreen: #00B707;
    --monolithorange: #FC6738;
    --monolithyellow: #FFD600;
    --darkgrey: #606060;
    --sidagap: 23px;
    --lineheight: 1.362em;
    --transition: all .5s cubic-bezier(0.54,0.22,0.22,0.97);
    --transitionmaxheight: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}

html {
	scroll-behavior: smooth;
}

body {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    line-height: 1.857em;
    font-weight: 400;
    color: var(--darkgrey);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    accent-color: var(--maincolor);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img, svg {
    vertical-align: top;
}
svg.oneeighty {
    transform: rotate(180deg);
}

strong, .strong, b, .b {
    font-weight: 700;
}
input, textarea, button, select {
    font-family: 'Open Sans', sans-serif;
    font-size: 1em;
    accent-color: var(--maincolor);
}
button {
    cursor: pointer;
    background: transparent;
    border: none;
    vertical-align: top;
    white-space: nowrap;
}
button svg {
    pointer-events: none;
}

a {
    color: var(--maincolor);
    text-decoration: none;
}

hr {
    border: 0 none;
    height: 1px;
    background: #d9d9d9;
    margin: 20px 0;
}

/* Typography */
h1, .h1 {
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
    margin-bottom: 10px;
}
h2, .h2 {
    font-size: 16px;
    line-height: var(--lineheight);
    font-weight: 700;
    margin-bottom: 20px;
}
/* Typography */

/* Local Bootstrap */
.form-control {
    width: 100%;
    line-height: 44px;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    padding: 0 15px;
    font-size: 16px;
}
.form-control:disabled {
    background: #F2F2F2;
    cursor: not-allowed;
}
textarea.form-control {
    padding: 9px 15px;
    line-height: 26px;
    height: 112px;
}
select.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg fill='none' height='18' viewBox='0 0 18 18' width='18' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m4.5 6.75 4.5 4.5 4.5-4.5' stroke='%239f0027' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/svg%3E");
    background-position: center right 14px;
    background-repeat: no-repeat;
    padding-right: 30px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.form-control[type="file"] {
    display: none;
}
.btn {
    display: inline-block;
    cursor: pointer;
    line-height: 3em;
    background: #999;
    color: #FFF;
    border-radius: 5px;
    white-space: nowrap;
    font-weight: 700;
    padding: 0 1em;
    transition: var(--transition);
    text-align: center;
}
.btn-info {
    background: var(--darkgrey);
}
.btn-primary {
    background: var(--maincolor);
}
.btn:hover {
    background: var(--maincolor)
}
.btn:disabled {
    background: #e9e9e9;
}
.btn-ellipsis {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.btn-lightgrey {
    color: #D9D9D9;
    background: #F2F2F2;
    /* border: 1px solid #D9D9D9; */
}
.btn-green {
    background: var(--monolithlightgreen);
}
.btn-outline {
    color: var(--darkgrey);
    line-height: calc(3m - 2px);
    background: transparent;
    border: 1px solid;
}
.btn-outline svg {
    margin: 0 10px 0 0;
}
.btn-outline svg path {
    stroke: var(--darkgrey);
}
.btn-block {
    width: 100%;
}
.btn svg {
    position: relative;
    top: 12px;
    margin-left: 10px;
    pointer-events: none;
}
.btn.btn-svg-left svg {
    margin-left: 0;
    margin-right: 10px;
}
.alert {
    border-radius: 5px;
    font-size: .857rem;
    line-height: var(--lineheight);
    color: #FFF;
    padding: 7px 7px 7px 30px;
    margin: 0 0 10px 0;
    background-color: #999;
    background-repeat: no-repeat;
    background-position: top 8px left 9px;
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='16' viewBox='0 0 15 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.5 14.25C10.9518 14.25 13.75 11.4518 13.75 8C13.75 4.54822 10.9518 1.75 7.5 1.75C4.04822 1.75 1.25 4.54822 1.25 8C1.25 11.4518 4.04822 14.25 7.5 14.25Z' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.5 10.5V8' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.5 5.5H7.5075' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}
.alert-danger {
    background-color: var(--maincolor);
}
.alert-success, .alert.active {
    background-color: var(--monolithlightgreen);
}
.alert-info, .alert.pending {
    background-color: var(--monolithorange);
}
.alert-wait {
    color: var(--darkgrey);
    background-color: #D9D9D9;
    border: 1px solid #999999;
    padding: 6px 6px 6px 29px;
    background-position: top 7px left 8px;
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.5 13.75C10.9518 13.75 13.75 10.9518 13.75 7.5C13.75 4.04822 10.9518 1.25 7.5 1.25C4.04822 1.25 1.25 4.04822 1.25 7.5C1.25 10.9518 4.04822 13.75 7.5 13.75Z' stroke='%23999999' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.5 3.75V7.5L10 8.75' stroke='%23999999' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}
/* Local Bootstrap */

/* classes */
.block {
    display: block;
}
.relative {
    position: relative;
}
.content {
    padding-bottom: 84px;
}
.center {
    text-align: center;
}
.left {
    text-align: left;
}
.right {
    text-align: right;
}
.hidden {
    display: none !important;
}
.inline-block {
    display: inline-block;
}
.width100 {
    width: 100%;
}
.width50 {
    width: 50%;
}
.width25 {
    width: 25%;
}
.width75 {
    width: 75%;
}
.width100px {
    width: 100px;
}
.width130px {
    width: 130px;
}
.marginbottom1em {
    margin-bottom: 1em;
}
.marginbottom15em {
    margin-bottom: 1.5em;
}
.marginbottom2em {
    margin-bottom: 2em;
}
.marginbottom5 {
    margin-bottom: 5px;
}
.marginbottom7 {
    margin-bottom: 7px;
}
.marginbottom10 {
    margin-bottom: 10px !important;
}
.margintop25 {
    margin-top: 25px;
}
.marginbottom15 {
    margin-bottom: 15px;
}
.marginbottom20 {
    margin-bottom: 20px;
}
.marginbottom40 {
    margin-bottom: 40px;
}
.marginbottom50 {
    margin-bottom: 50px;
}
.paddingbottom0 {
    padding-bottom: 0 !important;
}
.diasble_svg svg {
    pointer-events: none;
}
.red {
    color: var(--maincolor)
}
.nowrap {
    white-space: nowrap;
}
.flex-spacebeetwen {
    display: flex;
    justify-content: space-between;
}
.orange {
    color: var(--monolithorange);
}
.green {
    color: var(--monolithgreen);
}
/* classes */

/* table */
.table {
    width: 100%;
    border-collapse: collapse;
    line-height: 19px;
    margin-bottom: 20px;
}
.table thead {
    background: #d9d9d9;
    line-height: 37px;
    font-weight: 700;
}
.table tbody tr:nth-child(even) {
    background: #f2f2f2;
}
.table tbody td {
    padding: 11px 10px;
}
/* table */

/* Loader */
.loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 3s ease infinite;
    z-index: 1000;
    transition: var(--transition);
    transform: translateY(-10px);
}
.loader.active {
    transform: translateY(0);
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
/* Loader */

/* header */
/*
.top_red_banner {
    position: relative;
    padding: 34px 23px 5px 23px;
    font-size: 12px;
    line-height: var(--lineheight);
    color: #FFF;
    background: var(--maincolor);
}
*/
.top_red_banner {
    position: relative;
    display: flex;
    align-items: center;
    height: 55px;
    padding: 0 50px 0 23px;
    font-size: 12px;
    line-height: var(--lineheight);
    color: #FFF;
    background: var(--maincolor);
}
.top_red_banner__close {
    position: absolute;
    /* bottom: 5px; */
    bottom: 16px;
    right: 23px;
    width: 21px;
    height: 21px;
    transform: rotate(45deg);
}
.top_red_banner__close::before,
.top_red_banner__close::after {
    content: '';
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    height: 1px;
    background: #FFF;
}
.top_red_banner__close::after {
    left: 10px;
    width: 1px;
    right: auto;
    top: 0;
    bottom: 0;
    height: auto;
}
/* header */

/* Breadcrumbs */
.breadcrumbs__wrapper {
    display: flex;
    justify-content: space-between;
    padding: 17px 23px 10px 23px;
}
.breadcrumbs__wrapper .breadcrumbs {
    padding: 0;
}
.breadcrumbs {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    line-height: 16px;
    padding: 17px 23px 10px 23px;
}
.bradcrumbs_home {
    width: 14px;
    height: auto;
}
.breadcrumbs_on_2_colummns .breadcrumbs {
    margin: 0;
}
.breadcrumbs_on_2_colummns {
    max-width: var(--maxwidth);
    margin: 21px auto;
    padding: 0 var(--gap);
    display: grid;
    grid-template-columns: 1fr auto;
    gap: var(--gap)
}
.breadcrumbs_on_2_colummns .wrapper {
    max-width: none;
    margin: 0;
    padding: 0;
}
.breadcrumbs li {
    position: relative;
    margin: 2px 20px 2px 0;
}
.breadcrumbs li:first-child {
    margin-left: 0;
}
.breadcrumbs li:not(:last-child):after {
    content: '';
    position: absolute;
    top: 6px;
    right: -11px;
    width: 5px;
    height: 5px;
    color: var(--darkgrey);
    border-bottom: 1px solid;
    border-right: 1px solid;
    transform: rotate(-45deg);
}
.breadcrumbs a {
    color: var(--darkgrey);
}
.breadcrumbs a:hover {
    color: var(--maincolor);
}
.breadcrumbs a:hover svg path {
    stroke: var(--maincolor);
}
.breadcrumbs__steps {
    position: relative;
    top: -3px;
    display: flex;
    list-style: none;
    counter-reset: myCounter;
    font-size: 12px;
}
.breadcrumbs__steps li::before {
    counter-increment: myCounter;
    content: counter(myCounter);
    display: inline-block;
    text-align: center;
    width: 24px;
    line-height: 22px;
    border-radius: 50%;
    font-weight: 700;
    border: 1px solid var(--darkgrey);
    margin: 0 5px;
}
.breadcrumbs__steps li.current::before {
    color: #FFF;
    border-color: var(--maincolor);
    background: var(--maincolor);
}
/* Breadcrumbs */

/* animated placeholder */
.animated_placeholder {
    position: relative;
    height: 1.5rem;
    background: #f2f2f2;
    border-radius: 3px;
    /* margin: 1em 0; */
    overflow: hidden;
}
.animated_placeholder::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: content-placeholder-animation 1s linear infinite;
    background: linear-gradient(to right, rgba(15, 15, 15, 0) 0%, rgba(219, 219, 219, 0.4) 50%, rgba(255, 255, 255, 0) 100%);
}
/* animated placeholder */

/* Two Contacts */
.two_contacts {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 20px;
}
.one_contact {
    padding: 18px 20px;
    background: #F8F8F8;
    border-radius: 5px;
}
.one_contact__info {
    display: grid;
    grid-template-columns: 45px 1fr;
    gap: 15px;
    line-height: 26px;
}
.one_contact__info__image {
    width: 45px;
    height: 45px;
    border-radius: 50%;
}
.one_contact__info__name {
    line-height: 26px;
    font-weight: 700;
}
.one_contact__info__works {
    line-height: 26px;
    font-weight: 600;
    margin-bottom: 13px;
}
.one_contact__info__email a, .one_contact__info__phone a {
    color: var(--darkgrey);
}
.one_contact__info__buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin: 20px 0 0 0;
}
.one_contact__info__buttons .btn svg {
    top: 14px;
}
/* Two Contacts */

/* Meilisearch */
.product_hits  {
    margin-bottom: 20px;
}
.product_hits .ais-Hits-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
/* Meilisearch */

/* Button loader */
.submit_loader {
    min-width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.submit_loader__feedback {
    min-height: 3em;
}
.submit_loader * {
    pointer-events: none;
}
.submit_loader:disabled {
    cursor: not-allowed;
}
.submit_loader.waiting .waiting,
.submit_loader.loading .loading {
    display: block;
}
.submit_loader.loading .waiting,
.submit_loader.waiting .loading {
    display: none;
}
.round_spinner {
    display: block;
    font-size: 20px;
    width: 1em;
    height: 1em;
    border: 3px solid #FFF;
    border-radius: 50%;
    border-bottom: 0 none;
    border-right: 0 none;
    animation: rotating 1s linear infinite;
}
@keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}
/* Button loader */
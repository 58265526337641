.order_history_popup__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    visibility: hidden;
}
.order_history_popup__wrapper.active {
    visibility: visible;
}
.order_history_popup__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,.5);
    z-index: 1;
    transition: var(--transition);
    opacity: 0;
}
.order_history_popup__wrapper.active .order_history_popup__overlay {
    opacity: 1;
}
.order_history_popup {
    position: absolute;
    background: #FFF;
    width: calc(100% - 46px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    z-index: 2;
    padding: 14px 10px 27px 10px;
    border-radius: 5px;
    transition: var(--transition);
}
.order_history_popup__wrapper.active .order_history_popup {
    transform: translate(-50%, -50%) scale(1);
}
.order_history_popup__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 14px;
}
.order_history_popup__prodinfo {
    display: grid;
    grid-template-columns: 50px 1fr;
    gap: 14px;
    margin-bottom: 13px;
}
.order_history_popup__prodinfo__name {
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
}
.order_history_popup__prodinfo__image {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.order_history_popup__prodinfo__image img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}
.order_history_popup__prodinfo__itemid_uni {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 40px;
    font-size: 12px;
    line-height: 18px;
}
.order_history_popup__prodinfo__itemid_uni svg {
    margin-right: 10px;
}
.order_history_popup__table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    line-height: 40px;
    font-size: 12px;
}
.order_history_popup__table thead {
    background: #d9d9d9;
    font-weight: 700;
}
.order_history_popup__table tbody tr:nth-child(even) {
    background: #f2f2f2;
}
.order_history_popup__close {
    position: absolute;
    top: 17px;
    right: 16px;
    width: 21px;
    height: 21px;
    border: 1px solid var(--darkgrey);
    border-radius: 50%;
}
.order_history_popup__close:active {
    border-color: var(--maincolor);
}
.order_history_popup__close::before, .order_history_popup__close::after {
    content: '';
    position: absolute;
    top: 4px;
    left: 9px;
    width: 1px;
    height: 11px;
    transform: rotate(45deg);
    background: var(--darkgrey);
}
.order_history_popup__close::after {
    transform: rotate(-45deg);
}
.order_history_popup__close:active::before, .order_history_popup__close:active::after {
    background: var(--maincolor);
}
.order_history__content_placeholder {
    height: 2.5rem !important;
    width: 84px
}
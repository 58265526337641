.main_menu {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 200;
}
.main_menu__content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 23px;
    background: #f2f2f2;
    box-shadow: 0px -2px 12px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}
.main_menu__button {
    border-radius: 50%;
    transition: var(--transition);
}
.main_menu__button svg {
    pointer-events: none;
}
.main_menu__button.active svg .make_me_white_stroke {
    stroke: #FFF;
}
.main_menu__button.active svg .make_me_red_stroke {
    stroke: var(--maincolor);
}
.main_menu__button.active svg .make_me_red_fill {
    fill: var(--maincolor);
}
.main_menu__menu,
.main_menu__account
{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
    background: #FFF;
    z-index: 100;
    box-shadow: 0 -2px 12px rgba(0, 0, 0, 0.05);
    border-radius: 5px 5px 0 0;
    padding: 32px 23px 23px 23px;
    transition: var(--transition);
    transform: translateY(100%);
}
.main_menu__menu.active,
.main_menu__account.active
{
    transform: translateY(0);
}
.main_menu__menu ul,
.main_menu__account ul
{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 187px;
    list-style: none;
    font-weight: 700;
}
.main_menu__account ul {
    height: 316px;
}
.main_menu__menu ul a,
.main_menu__menu ul button,
.main_menu__account ul a,
.main_menu__account ul button {
    display: block;
    color: var(--darkgrey);
    font-weight: 700;
}
.main_menu__account ul button {
    display: flex;
    align-items: baseline;
}
.main_menu__menu ul a:hover,
.main_menu__menu ul button:hover,
.main_menu__menu ul a:active,
.main_menu__menu ul button:active,
.main_menu__account ul a:hover,
.main_menu__account ul button:hover,
.main_menu__account ul a:active,
.main_menu__account ul button:active
{
    color: var(--maincolor);
}
.main_menu__menu ul a svg,
.main_menu__menu ul button svg,
.main_menu__account ul a svg,
.main_menu__account ul button svg
{
    position: relative;
    top: 3px;
    margin-right: 26px;
}
.main_menu__menu ul a:hover svg path,
.main_menu__menu ul button:hover svg path,
.main_menu__menu ul a:active svg path,
.main_menu__menu ul button:active svg path,
.main_menu__account ul a:hover svg path,
.main_menu__account ul button:hover svg path,
.main_menu__account ul a:active svg path,
.main_menu__account ul button:active svg path
{
    stroke: var(--maincolor);
}
.main_menu__search {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
    background: #FFF;
    z-index: 100;
    transition: var(--transition);
    transform: translateY(100%);
    border-radius: 5px 5px 0 0;
}
.main_menu__search.active {
    transform: translateY(0);
}
.main_menu__search__input,
.main_menu__search .ais-SearchBox-form input[type="search"] {
    width: 100%;
    line-height: 49px;
    font-size: 16px;
    padding: 0 46px 0 23px;
    color: #999;
    border: 0 none;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #D9D9D9;
    box-shadow: 0px -2px 12px rgba(0, 0, 0, 0.1);
}
.main_menu__search .ais-SearchBox-form {
    margin: 0;
}
.ais-Hits-list {
    list-style: none;
}
.main_menu__search__submit {
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 16px;
    width: 18px;
    height: 18px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg fill='none' height='15' viewBox='0 0 17 15' width='17' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%23606060' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'%3E%3Cpath d='m7.58808 13.2353c3.37872 0 6.11762-2.739 6.11762-6.11766 0-3.37868-2.7389-6.11764-6.11762-6.11764-3.37867 0-6.11762 2.73896-6.11762 6.11764 0 3.37866 2.73895 6.11766 6.11762 6.11766z'/%3E%3Cpath d='m15.9998 14.0001-3.3265-2.9569'/%3E%3C/g%3E%3C/svg%3E");
}
.main_menu__account li.devider {
    height: 1px;
    background: #d9d9d9;
}
.main_menu__cart {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
    background: #FFF;
    z-index: 100;
    transition: var(--transition);
    transform: translateY(100%);
    border-radius: 5px 5px 0 0;
}
.main_menu__cart.active {
    transform: translateY(0);
}
.main_menu__caer__preloader {
    height: 5rem;
    width: 100%;
    animation: content-placeholder-animation 1s linear infinite;
    background: linear-gradient(to right, rgba(15, 15, 15, 0) 0%, rgba(219, 219, 219, 0.4) 50%, rgba(255, 255, 255, 0) 100%);
}
.main_menu__cart__title {
    padding: 20px 23px 10px 23px;
    font-weight: 700;
    color: var(--maincolor);
    font-size: 1.14286rem;
    line-height: var(--lineheight);
}
.main_menu__cart__under_title {
    color: #999;
    padding: 0 23px 20px 23px;
}
.main_menu__cart__list {
    list-style: none;
    max-height: calc(100vh - 240px);
    overflow-y: auto;
}
.no_carts {
    font-size: 1.14286rem;
    line-height: var(--lineheight);
    padding: 1em 23px;
}
.main_menu__cart__list__button {
    width: 100%;
    display: flex;
    padding: 0 23px;
    justify-content: space-between;
    font-size: .75rem;
    line-height: 50px;
    color: var(--darkgrey);
}
.main_menu__cart__list__button * {
    pointer-events: none;
}
.main_menu__cart__list__button.current,
.main_menu__cart__list__button:active,
.main_menu__cart__list__button:hover {
    background: #f2f2f2;
}
.main_menu__cart__list__button svg {
    position: relative;
    top: 16px;
}
.main_menu__cart__all_carts {
    padding: 15px 23px;
    background: #f8f8f8;
}
.main_menu__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 90;
    transition: var(--transition);
    visibility: hidden;
    opacity: 0;
}
.main_menu__overlay.active {
    visibility: visible;
    opacity: 1;
}
.main_menu__single_cart {
    display: none;
    padding: 23px 23px 8px 23px;
}
.main_menu__single_cart.active {
    display: block;
}
.main_menu__single_cart__title {
    font-weight: 700;
    color: var(--maincolor);
    font-size: 1.14286rem;
    line-height: var(--lineheight);
    margin-bottom: 10px;
}
.main_menu__single_cart__under_title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 9px;
}
.main_menu__single_cart__under_title div:first-child {
    color: #999;
}
.main_menu__single_cart__content__table {
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 7px;
    display: none;
}
.main_menu__single_cart__content__table button svg path {
    stroke: var(--maincolor) ;
}
.main_menu__single_cart__content__table:last-child {
    border-bottom: 0 none;
}
.main_menu__single_cart__content__table:nth-child(1),
.main_menu__single_cart__content__table:nth-child(2),
.main_menu__single_cart__content__table:nth-child(3),
.main_menu__single_cart__content__table:nth-child(4),
.main_menu__single_cart__content__table:nth-child(5)
{
    display: table;
}
.main_menu__single_cart__content__table thead {
    text-align: left;
}
.main_menu__single_cart__content__table tbody {
    font-size: 12px;
    line-height: var(--lineheight);
    color: #999;
}
.main_menu__single_cart__content__table th {
    line-height: var(--lineheight);
}
.main_menu__single_cart__content__table__summ {
    font-size: 14px;
    font-weight: 700;
    color: var(--darkgrey);
}
.main_menu__single_cart__content {
    max-height: calc(100vh - 410px);
    overflow-y: auto;
}
.main_menu__single_cart__footer {
    padding: 15px 0 7px 0;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 30px;
    align-items: center;
}
.button_height_45 {
    height: 45px;
}
.main_menu__single_cart__footer__carttotal {
    display: block;
    line-height: 1em;
    font-size: 12px;
    font-weight: 700;
}
.main_menu__single_cart__footer__exclvat {
    display: block;
    line-height: 1em;
    font-size: 12px;
    color: #999;
    margin-bottom: 2px;
}
.main_menu__single_cart__footer__summ {
    display: block;
    line-height: 1em;
    font-size: 20px;
    font-weight: 700;
}

.main_menu__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 44px;
}
.main_menu__logo img,
.main_menu__logo svg {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.search_results {
    position: fixed;
    bottom: 84px;
    left: 0;
    right: 0;
    background: #FFF;
    max-height: calc(100vh - 120px);
    z-index: 95;
    border-radius: 5px 5px 0 0;
    overflow-y: auto;
    padding: 23px 23px 66px 23px;
    box-shadow: 0 -2px 12px rgb(0 0 0 / 5%);
    transition: var(--transition);
    transform: translateY(100%);
}
.search_results.active {
    transform: translateY(0);
}

.main_menu__search .ais-SearchBox-form button[type="submit"] svg {
    width: 20px;
    height: 20px;
}

@keyframes content-placeholder-animation {
    0% {
      transform: translateX(-50%);
    }
    100% {
      transform: translateX(300%);
    }
}
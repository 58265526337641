.feedback_content {
    padding: 0 23px;
}
.feedback_content h1 {
    margin-bottom: 20px;
}
.feedback_content .under_h1 {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 40px;
}
.feedback_grey {
    color: #FFF;
    background: #999999;
    border-radius: 5px;
    padding: 20px;
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 30px;
}
.feedback_grey.light_grey {
    background: #F2F2F2;
    border: 1px solid #D9D9D9;
    color: var(--darkgrey);
}
.feedback_grey__header {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 14px;
}
.feedback_grey__view_policy {
    font-weight: 600;
    margin-top: 14px;
}
.feedback_grey__view_policy svg {
    position: relative;
    top: 3px;
    margin-right: 5px;
}
.feedback_grey.light_grey .feedback_grey__view_policy svg path {
    stroke: var(--darkgrey);
}

.feedback_history {
    list-style: none;
    margin-bottom: 30px;
}
.feedback_history__li {
    margin-bottom: 5px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
}
.feedback_history__li:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
.feedback_history__first {
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto auto;
    gap: 0 20px;
    padding: 9px 43px 10px 22px;
    font-size: 12px;
    line-height: 24px;
    color: #999999;
}
.feedback_history__first span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.feedback_history__first strong {
    color: var(--darkgrey);
    font-size: 14px;
    line-height: 26px;
    white-space: nowrap;
}
.feedback_history__first__status {
    display: inline-block;
    vertical-align: baseline;
    line-height: 24px;
    padding: 0 8px;
    color: #FFF;
    background: #999;
    border-radius: 5px;
}
/* page_number
}
.feedback_history__first__status.resolved,
.feedback_history__first__status.active {
    background: var(--monolithgreen);
} */
.feedback_history__first__button {
    position: absolute;
    width: 32px;
    height: 32px;
    right: 8px;
    top: 19px;
}
.feedback_history__first__button::after {
    content: '';
    position: absolute;
    top: 10px;
    left: 12px;
    width: 10px;
    height: 10px;
    color: #999;
    border-bottom: 1px solid;
    border-right: 1px solid;
    transform: rotate(45deg);
}
.feedback_history__second {
    display: none;
    border-top: 1px solid #d9d9d9;
    padding: 12px 22px 16px 22px;
    font-size: 12px;
    line-height: 16px;
    color: #999;
}
.feedback_history__li.active .feedback_history__second {
    display: block;
}
.feedback_history__second__type {
    font-size: 14px;
    line-height: 26px;
    color: var(--darkgrey);
    margin-bottom: 8px;
}
.feedback_history__second__type strong {
    font-weight: 600;
}
.feedback_history__second__view_complaint {
    display: block;
    width: 100%;
    background: #F2F2F2;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    color: var(--darkgrey);
    font-size: 12px;
    line-height: 16px;
    padding: 7px;
    margin: 12px 0 0 0;
    text-align: center;
}
.feedback_history__second__view_complaint svg {
    display: block;
    margin: 0 auto 5px auto;
}

.feedback_grid {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-bottom: 30px;
}
.feedback_grid a {
    display: block;
    height: 124px;
    background: #F8F8F8;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    color: var(--darkgrey);
    font-weight: 700;
    text-align: center;
    padding: 24px 24px 0 24px;
    line-height: 20px;
}
.feedback_grid a svg {
    display: block;
    margin: 0 auto 10px auto;
}
.feedback_grid li .alert {
    display: block;
    padding: 0;
    position: relative;
    bottom: 105px;
    left: calc(50% + 7px);
    width: 24px;
    line-height: 24px;
    font-size: 12px;
    text-align: center;
    color: #FFF;
    background: var(--maincolor);
    border-radius: 50%;
    font-weight: 400;
}

.feedback__label {
    display: block;
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 10px;
}
.feedback_submit__form .form-control {
    margin-bottom: 15px;
}
.wrapprePhone div .form-control{
    height: 46px !important;
    width: 100%;
}
.wrapprePhone .react-tel-input{
    margin-bottom: 15px;
}
.feedback__checkboxes {
    display: block;
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 15px;
}
.feedback__checkboxes input[type="checkbox"] {
    margin-right: 16px;
}
.feedback__checkboxes a {
    color: var(--darkgrey);
    text-decoration: underline;
}

.feedback_details {
    background: #F8F8F8;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    padding: 20px;
    line-height: 26px;
    margin-bottom: 30px;
}
.feedback_details .flex-spacebeetwen {
    margin: 7px 0;
}
.feedback_details hr {
    margin: 14px 0;
}
.feedback_details strong {
    font-weight: 600;
}
.print_feedback {
    font-size: 14px;
    line-height: 26px;
    font-weight: 600;
    color: var(--darkgrey);
}
.print_feedback svg {
    position: relative;
    top: 3px;
    margin-right: 5px;
}
.print_feedback svg path {
    stroke: var(--darkgrey);
}

.feedback_from_user {
    background: #F2F2F2;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 30px;
}
.feedback_from_user h2 {
    margin-bottom: 14px;
}
.feedback_from_user__sent_by {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 14px;
}
.feedback_from_user__text {
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 14px;
}
.feedback_from_user__attachments {
    line-height: 26px;
    font-weight: 600;
    margin-bottom: 13px;
}
.feedback_from_user__attachments_images,
.feedback_from_user__attachments_images_upload {
    display: flex;
    flex-wrap: wrap;
}
.feedback_from_user__attachments_images a {
    display: flex;
    margin: 0 10px 0 0;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
}
.feedback_from_user__attachments_images img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.feedback_from_user__attachments_images_upload img{
    object-fit: cover;
    width: 95px;
    height: 60px;
    margin: 7px 7px;
}
.feedback__add_reply {
    margin-bottom: 30px;
}

.feedback__reply_from {
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 30px;
}
.feedback__reply_from h2 {
    margin-bottom: 14px;
}

.feedback_red {
    color: #FFF;
    background: var(--maincolor);
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 30px;
}
.feedback_red h2 {
    margin-bottom: 14px;
}
.feedback_red__view_receipt {
    margin-top: 14px;
    font-weight: 600;
    line-height: 26px;
    color: #FFF;
}
.feedback_red__view_receipt svg {
    position: relative;
    top: 3px;
    margin-right: 5px;
}

.feedback__uploaded_images {
    display: flex;
    flex-wrap: wrap;
}
.feedback__uploaded_image {
    display: flex;
    width: 100px;
    height: 100px;
    justify-content: center;
    align-items: center;
    border: 2px solid #CCC;
    margin: 0 20px 20px 0;
}
.feedback__uploaded_image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.feedback_policy {
    text-decoration: underline;
}
.feedback_policy:hover {
    color: var(--maincolor);
}
.wrapper_loading_dots_feedback {
    align-items: inherit !important;
    height: 36px !important;
}
.wrapper_loading_dots_feedback .loadingDot {
    background-color: #fff !important;
}
.shopping_carts__content {
    padding: 0 23px;
}

/* .h1_popup {
    position: absolute;
    top: -35px;
    left: 23px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    transition: var(--transition);
    visibility: hidden;
    opacity: 0;
    transform: translateY(-20px);
    z-index: 1;
}
.h1_popup.active {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
} */
/* .h1_popup_button {
    display: block;
    width: 160px;
    padding: 4px 10px;
    white-space: normal;
    background: #f2f2f2;
    line-height: 34px;
    font-size: 14px;
    color: #666;
    text-align: left;
    font-weight: 400;
    word-wrap: break-word;
}
.h1_popup>*:first-child {
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #d9d9d9;
}
.h1_popup>*:last-child {
    border-radius: 0 0 5px 5px;
    background: #f8f8f8;
} */

.shopping_carts__list {
    margin-bottom: 30px;
}
.shopping_carts__item {
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    margin-bottom: 10px;
}
.shopping_cart__item_selecte {
    background: #D9D9D9 !important;
}
.shopping_carts__item__visible {
    position: relative;
    padding: 14px 46px 12px 21px;
    
}
.shopping_carts__item:nth-child(2n) {
    background: #f8f8f8;
}
.shopping_carts__item__info {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    line-height: 16px;
    color: #999;
}
.shopping_carts__item__info.in_hidden {
    padding: 0 33px 14px 8px;
}
.shopping_carts__item__info strong {
    font-size: 14px;
    line-height: 26px;
    color: var(--darkgrey);
    font-weight: 700;
}
.shopping_carts__item__button {
    position: absolute;
    width: 32px;
    height: 32px;
    right: 8px;
    top: 19px;
}
.shopping_carts__item__button::after {
    content: '';
    position: absolute;
    top: 10px;
    left: 12px;
    width: 10px;
    height: 10px;
    color: #999;
    border-bottom: 1px solid;
    border-right: 1px solid;
    transform: rotate(45deg);
}
.shopping_carts__item__hidden {
    display: none;
    border-top: 1px solid #d9d9d9;
    padding: 13px 13px 21px 13px;
}
.shopping_carts__item.active .shopping_carts__item__hidden {
    display: block;
}
.shopping_carts__item__hidden__buttons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 7px;
}
.shopping_carts__item__hidden__button {
    justify-content: center;
    height: 53px;
    background: #F2F2F2;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    color: var(--darkgrey);
    padding-top: 3px;
}
.shopping_carts__item__hidden__button svg {
    display: block;
    margin: 0 auto 5px auto;
}

.shopping_carts__alert__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    visibility: hidden;
    z-index: 200;
}
.shopping_carts__alert__wrapper.active {
    visibility: visible;
}
.shopping_carts__alert__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,.5);
    z-index: 1;
    transition: var(--transition);
    visibility: hidden;
    opacity: 0;
}
.shopping_carts__alert__wrapper.active .shopping_carts__alert__overlay {
    visibility: visible;
    opacity: 1;
}
.shopping_carts__alert {
    position: absolute;
    top: calc(50% - 102px);
    left: calc(50% - 164px);
    width: 328px;
    padding: 42px 31px 38px 31px;
    background: #FFFFFF;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    z-index: 2;
    transition: var(--transition);
    visibility: hidden;
    opacity: 0;
    transform: translateY(-20px);
}
.shopping_carts__alert__wrapper.active .shopping_carts__alert {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}
.shopping_carts__alert__text {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    margin-bottom: 31px;
}
.shopping_carts__alert__buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 9px;
}
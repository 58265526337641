/* 404 */
.nomatch_content {
    text-align: center;
    padding: 0 23px;
}
.static_page_content {
    padding: 0 23px;
}
.big_404 {
    margin: 6px 0 0 0;
    font-size: 100px;
    font-weight: 700;
    color: #f2f2f2;
    line-height: 136px;
}
.oh_no {
    font-size: 20px;
    line-height: var(--lineheight);
    font-weight: 700;
    margin-bottom: 15px;
}
.header_ervice_page {
    margin: 30px 23px
}
.service_page_title {
    font-size: 28px;
    line-height: var(--lineheight);
    color: #d4d4d4;
    font-weight: 700;
    margin: 35px 0px;
}
.you_might {
    margin-bottom: 35px;
}
.you_can_use_search {
    font-weight: 700;
    margin-bottom: 23px;
}
.nomatch_search_form {
    position: relative;
    margin-bottom: 48px;
}
.nomatch_search_form input {
    padding-right: 42px;
}
.nomatch_search_form__submit {
    position: absolute;
    top: 14px;
    right: 14px;
    width: 18px;
    height: 18px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg fill='none' height='15' viewBox='0 0 17 15' width='17' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%23606060' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'%3E%3Cpath d='m7.58808 13.2353c3.37872 0 6.11762-2.739 6.11762-6.11766 0-3.37868-2.7389-6.11764-6.11762-6.11764-3.37867 0-6.11762 2.73896-6.11762 6.11764 0 3.37866 2.73895 6.11766 6.11762 6.11766z'/%3E%3Cpath d='m15.9998 14.0001-3.3265-2.9569'/%3E%3C/g%3E%3C/svg%3E");
}
/* 404 */


/* Contacts */
.contacts_content {
    padding: 0 23px;
}

.contacts_content h1 {
    margin-bottom: 25px;
}

/* .contacts_image {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 30px;
} */

.contacts_header {
    line-height: 26px;
    font-weight: 700;
    margin-bottom: 20px;
}

.contacts_grey {
    background: #f8f8f8;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 10px;
}
.contacts_grey hr {
    margin: 0 0 20px 0;
}
.contacts_grey__columns {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0 5px;
    line-height: 26px;
}
.contacts_grey__columns a {
    color: var(--darkgrey);
}
.contacts_grey__columns a[href^='mailto:'] {
    color: var(--maincolor);
}
.contacts_grey__columns ~ hr {
    margin-top: 20px;
}
/* Contacts */

/* Product Catalogs */
.product_catalog__content {
    padding: 0 23px;
}
.product_catalog__single_big,
 .promotional_booklets__single_big {
    font-weight: 700;
    line-height: 26px;
    text-align: center;
    margin-bottom: 40px;
}
.product_catalog__single_big div:nth-child(2),
.promotional_booklets__single_big div:nth-child(2){
    padding-top: 15px;
}
.product_catalog__single_big img,
 .promotional_booklets__single_big img {
    display: block;
    width: 218px;
    height: auto;
    margin: 0 auto;
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.15));
}
.product_catalogs_colum__save {
    display: flex;
    justify-content: center;
    margin: 0.5rem;
}
.product_catalog__single_big__dates, .promotional_booklets__single_big__dates {
    font-weight: 400;
    line-height: var(--lineheight);
}
/* Product Catalogs */

/* Promotional Booklets */
.promotional_booklets__list {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 17px 8px;
    margin-bottom: 20px;
}
.promotional_booklets__list__img {
    display: flex;
    /* height: 140px; */
}
.promotional_booklets__list__img img {
    object-fit: cover;
    width: 100%;
}
.promotional_booklets__list li {
    position: relative;
    border: 1px solid transparent;
    cursor: pointer;
    border-radius: 5px 5px;
}
.promotional_booklets__list li::before {
    content: 'Current';
    display: block;
    line-height: 28px;
    text-align: center;
    color: #FFF;
    font-weight: 700;
    background: var(--maincolor);
    border-radius: 4px 4px 0 0;
    visibility: hidden;
}
.promotional_booklets__list li.current::before {
    visibility: visible;
}
.promotional_booklets__list li * {
    pointer-events: none;
}
.promotional_booklets__list li.current {
    border-color: var(--maincolor);
}
.promotional_booklets__list img {
    width: 100%;
    height: auto;
}
.promotional_booklets__list__dates {
    padding: 5px 0;
    text-align: center;
    border-radius: 0 0 4px 4px;
    font-size: 12px;
    line-height: var(--lineheight);
}
.promotional_booklets__list li.current .promotional_booklets__list__dates {
    color: #FFF;
    background: var(--maincolor);
}
/* Promotional Booklets */

/* Videos */
.videos_content {
    padding: 0 23px;
}
.videos_content h1 {
    margin-bottom: 24px;
}
.main_video_placeholder {
    margin-bottom: 28px;
    width: 100%;
    height: auto;
    border-radius: 5px;
}
.videos_content__description {
    line-height: 26px;
    margin-bottom: 20px;
}
.videos_content__list {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-bottom: 40px;
}
.videos_content__list img {
    width: 100%;
    height: auto;
}
.videos_content__list img:hover {
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.15));
}

.video_wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    z-index: 1000;
}
.video_wrapper.active {
    visibility: visible;
}
.video_overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000;
    z-index: 10;
    transition: var(--transition);
    opacity: 0;
}
.video_wrapper.active .video_overlay {
    opacity: 1;
}
.video_content {
    position: relative;
    aspect-ratio: 16 / 9;
    width: 100%;
    height: auto;
    z-index: 20;
    transition: var(--transition);
    opacity: 0;
    transform: translateY(-50px);
}
.video_wrapper.active .video_content {
    opacity: 1;
    transform: translateY(0);
}
.video_content iframe {
    width: 100%;
    height: 100%;
}
/* Videos */

/* Modal popup */
.modal_popup__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    visibility: hidden;
}
.modal_popup__wrapper.active {
    visibility: visible;
}
.modal_popup__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,.5);
    z-index: 1;
    transition: var(--transition);
    opacity: 0;
}
.modal_popup__wrapper.active .modal_popup__overlay {
    opacity: 1;
}
.modal_popup__content {
    position: absolute;
    top: 50%;
    left: calc(50%);
    /* min-width: 300px; */
    min-width: min-content;
    max-width: 380px;
    background: #FFF;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    padding: 50px 40px 40px 40px;
    text-align: left;
    transform: translate(-50%, -50%) scale(0);
    transition: var(--transition);
    z-index: 2;
}

.modal_popup__wrapper.active .modal_popup__content {
    transform: translate(-50%, -50%) scale(1);
}
.modal_popup__content__close {
    position: absolute;
    top: 19px;
    right: 22px;
    width: 21px;
    height: 21px;
    border: 1px solid #999;
    border-radius: 50%;
}
.modal_popup__content__close::before, .modal_popup__content__close::after {
    content: '';
    position: absolute;
    top: 4px;
    left: 9px;
    width: 1px;
    height: 11px;
    transform: rotate(45deg);
    background: #999;
}
.modal_popup__content__close::after {
    transform: rotate(-45deg);
}
.modal_popup__content__close:hover {
    border-color: var(--darkgrey);
}
.modal_popup__content__close:hover::before, .modal_popup__content__close:hover::after {
    background: var(--darkgrey);
}
.modal_popup__header {
    color: var(--maincolor);
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.35em;
    margin-bottom: 4px;
    overflow-wrap: break-word;
    /* hyphens: auto; */
}
.modal_popup__r_u_sure {
    color: #999;
    margin-bottom: 21px;
}
.wrapper_content_show_rows {
    display: block;
    margin: 0 0 25px 0;
}
.wrapper_bottom_button {
    display: flex;
    justify-content: center;
}
.wrapper_content_show_rows div{
    margin: 5px;
}
.rename_cart__popup__buttons_local {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 11px;
    padding: 0 ;
}
/* Modal popup */

/* dangerouslyInnerHTM */
/* .dangerouslyInnerHTM__container_text {
    text-align: justify;
} */
.dangerouslyInnerHTM__container_text ol li {
    margin-left: 2em;
}
.dangerouslyInnerHTM__container_text p {
    text-indent: 1rem;
}
/* dangerouslyInnerHTM */
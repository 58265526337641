/* invoices */

.invoices_content, .returns_content, .return_content {
    padding: 0 23px;
}
.invoices_content h1 {
    margin-bottom: 17px;
}

.invoices {
    margin-bottom: 20px;
}
.invoice {
    background: #FFF;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    margin-bottom: 10px;
}
.invoice:hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
}
.invoice:nth-child(even) {
    background: #f8f8f8;
}
.invoice__first {
    position: relative;
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr;
    font-size: 12px;
    line-height: 16px;
    color: #999;
    padding: 13px 46px 10px 21px;
}
.invoice__first strong {
    font-size: 14px;
    line-height: 26px;
    color: var(--darkgrey);
    white-space: nowrap;
}
.invoice__first__status {
    display: inline-block;
    vertical-align: top;
    color: #FFF;
    background: #999;
    line-height: 24px;
    border-radius: 5px;
    padding: 0 8px;
}
.invoice__first__status.pending {
    background: var(--monolithorange);
}
.invoice__first__status.paid {
    background: var(--monolithgreen);
}
.invoice__first__button {
    position: absolute;
    width: 32px;
    height: 32px;
    right: 8px;
    top: 19px;
}
.invoice__first__button::after {
    content: '';
    position: absolute;
    top: 10px;
    left: 12px;
    width: 10px;
    height: 10px;
    color: #999;
    border-bottom: 1px solid;
    border-right: 1px solid;
    transform: rotate(45deg);
}
.invoice.active .invoice__first__button::after {
    transform: translateY(6px) rotate(225deg);
}

.invoice__second {
    padding: 7px 12px 17px 12px;
    border-top: 1px solid #d9d9d9;
    font-size: 12px;
    line-height: 16px;
    color: #999;
    display: none;
}
.invoice.active .invoice__second {
    display: block;
}
.invoice__second__info {
    padding: 0 8px;
}
.invoice__second__info__three_columns {
    margin: 9px 0 13px 0;
    display: flex;
    justify-content: space-between;
}
.invoice__second__buttons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 7px;
}
.invoice__second__button {
    font-size: 12px;
    color: var(--darkgrey);
    background: #F2F2F2;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    padding: 7px;
    text-align: center;
}
.invoice__second__button svg {
    display: block;
    height: 18px;
    margin: 0 auto 5px auto;
}
.invoice__second__button.disabled {
    color: #d9d9d9;
}
.invoice__second__button.disabled svg path,
.invoice__second__button.disabled svg polyline {
    stroke: #d9d9d9;
}

.invoices_popup__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 200;
    visibility: hidden;
}
.invoices_popup__wrapper.active {
    visibility: visible;
}
.invoices_popup__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    transition: var(--transition);
    visibility: hidden;
    opacity: 0;
}
.invoices_popup__wrapper.active .invoices_popup__overlay {
    visibility: visible;
    opacity: 1;
}
.invoices_popup {
    padding: 50px 37px 0 37px;
    position: absolute;
    top: calc(50% - 120px);
    left: calc(50% - 164px);
    width: 328px;
    height: 204px;
    background: #FFF;
    border-radius: 5px;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.4);
    transition: var(--transition);
    z-index: 2;
    visibility: hidden;
    opacity: 0;
    transform: translateY(20px);
}
.invoices_popup__wrapper.active .invoices_popup {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}
.invoices_popup__header {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    margin-bottom: 30px;
}
.invoices_popup__text {
    line-height: 19px;
}
.invoices_popup__close {
    position: absolute;
    top: 17px;
    right: 15px;
    width: 21px;
    height: 21px;
    border: 1px solid #999;
    border-radius: 50%;
}
.invoices_popup__close::before,
.invoices_popup__close::after {
    content: '';
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 9px;
    width: 1px;
    background: #999;
    transform: rotate(45deg);
}
.invoices_popup__close::after {
    transform: rotate(-45deg);
}
.invoices_popup__close:active {
    border-color: var(--maincolor);
}
.invoices_popup__close:active::before,
.invoices_popup__close:active::after {
    background: var(--maincolor);
}

/* invoices */


/* returns */
.returns_content h1 {
    margin-bottom: 17px;
}
.returns_list {
    margin-bottom: 20px;
}
.returns_list__item {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    margin-bottom: 10px;
}
.returns_list__item:nth-child(even) {
    background: #f8f8f8;
}
.returns_list__item:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
.returns_list__item__first {
    position: relative;
    display: grid;
    grid-template-columns: 3fr 2fr 2fr;
    font-size: 12px;
    line-height: 16px;
    color: #999;
    padding: 13px 46px 15px 19px;
    white-space: nowrap;
}
.returns_list__item__first strong {
    font-size: 14px;
    line-height: 26px;
    color: var(--darkgrey);
}
.returns_list__item__button {
    position: absolute;
    width: 32px;
    height: 32px;
    right: 8px;
    top: 19px;
}
.returns_list__item__button::after {
    content: '';
    position: absolute;
    top: 10px;
    left: 12px;
    width: 10px;
    height: 10px;
    color: #999;
    border-bottom: 1px solid;
    border-right: 1px solid;
    transform: rotate(45deg);
}
.returns_list__item__second {
    display: none;
    border-top: 1px solid #d9d9d9;
    padding: 13px 12px 19px 12px;
    font-size: 12px;
    line-height: 16px;
    color: #999;
}
.returns_list__item.active .returns_list__item__second {
    display: block;
}
.returns_list__item__second__info {
    padding: 0 7px;
}
.returns_list__item__second__buttons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 7px;
}
.returns_list__item__second__button {
    background: #f2f2f2;
    color: var(--darkgrey);
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    padding: 7px;
    text-align: center;
}
.returns_list__item__second__button svg {
    display: block;
    margin: 0 auto 5px auto;
}
/* returns */



/* return */
.return_content h1 {
    margin-bottom: 17px;
}
.order_reference {
    background: #FFF;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    padding: 20px 20px 6px 20px;
    margin-bottom: 18px;
}
.order_reference h2 {
    color: var(--maincolor);
    margin-bottom: 14px;
}
.order_reference__table {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
    line-height: 26px;
}
.order_reference__table strong {
    font-weight: 600;
}

.products_have_been_returned {
    line-height: 26px;
    font-weight: 700;
}

.return_products {
    margin-bottom: 20px;
}
.return_products__item {
    display: grid;
    gap: 12px;
    border-bottom: 1px solid #d9d9d9;
    padding: 10px 0 21px 0;
}
.return_products.one .return_products__item {
    grid-template-columns: 12px 55px 1fr;
    padding: 16px 0 15px 8px;
}
.return_products.two .return_products__item {
    grid-template-columns: 55px 1fr 62px;
}
.return_products.static .return_products__item {
    grid-template-columns: 55px 1fr 62px;
}
.return_products__item__checkbox {
    display: flex;
    justify-content: center;
}
.return_products.two .return_products__item__checkbox {
    display: none;
}
.return_products__item__image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55px;
    height: 55px;
}
.return_products__item__image img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}
.return_products.one .return_products__item__count {
    display: none;
}
.return_products__item__count {
    display: flex;
    align-items: center;
}
.return_products__item__count button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 62px;
    line-height: 40px;
    height: 40px;
    color: #FFF;
    text-align: center;
    border-radius: 5px;
    background: var(--monolithlightgreen);
}
.return_products__item__count.static button {
    background: var(--darkgrey);
}
.return_products__item__count button * {
    pointer-events: none;
}
.return_products__item__count button[data-qty="0"] {
    background: var(--darkgrey);
}
.return_products__item__count button svg {
    display: none;
}
.return_products__item__count button[data-qty="0"] span {
    display: none;
}
.return_products__item__count button[data-qty="0"] svg {
    display: block;
}
.return_products__item__link {
    display: block;
    font-weight: 700;
    line-height: 20px;
    max-height: 40px;
    color: var(--darkgrey);
    overflow: hidden;
    margin-bottom: 2px;
}
.return_products__item__history {
    margin-top: 10px;
    font-size: 12px;
    line-height: 18px;
}

.return_popup__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 200;
    visibility: hidden;
}
.return_popup__wrapper.active {
    visibility: visible;
}
.return_popup__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    transition: var(--transition);
    opacity: 0;
}
.return_popup__wrapper.active .return_popup__overlay {
    opacity: 1;
}
.return_popup {
    position: absolute;
    top: 20px;
    width: 329px;
    left: calc(50% - 164px);
    background: #FFF;
    border-radius: 5px;
    z-index: 2;
    padding: 24px;
    transition: var(--transition);
    opacity: 0;
    transform: translateY(20px);
}
.return_popup__wrapper.active .return_popup {
    opacity: 1;
    transform: translateX(0);
}
.return_popup__header {
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    line-height: var(--lineheight);
    margin-bottom: 20px;
    line-height: 26px;
}
.return_popup hr {
    margin-bottom: 40px;
}
.return_popup__input_disabled {
    display: block;
    text-align: center;
    width: 178px;
    line-height: 48px;
    margin: 0 auto 40px auto;
    background: #f2f2f2;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    font-size: 1rem;
    color: var(--darkgrey);
    cursor: not-allowed;
}
.return_popup__input_wrapper {
    width: 178px;
    display: grid;
    grid-template-columns: 50px 1fr 50px;
    margin: 0 auto 40px auto;
}
.return_popup__input_input {
    width: 100%;
    border: 0 none;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    text-align: center;
    font-size: 16px;
    line-height: 48px;
    color: var(--darkgrey);
}
.return_popup__input_button {
    line-height: 48px;
    border: 1px solid #d9d9d9;
    color: var(--darkgrey);
    font-size: 30px;
    border-radius: 5px 0 0 5px;
}
.return_popup__input_button.disabled {
    pointer-events: none;
    background: #d9d9d9 !important;
    border-color: #d9d9d9 !important;
}
.return_popup__input_button.plus {
    background: var(--monolithlightgreen);
    color: #FFF;
    border-radius: 0 5px 5px 0;
    border-color: var(--monolithlightgreen);
}
.return_popup__input {
    display: block;
    width: 178px;
    line-height: 48px;
    background: #f2f2f2;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    text-align: center;
    margin: 0 auto 40px auto;
}
.return_popup__list {
    list-style: none;
    margin: 0 21px 20px 21px;
}
.return_popup__list li {
    margin: 0 0 10px 0;
}
.return_popup__list input {
    margin-right: 19px;
}
.return_popup__buttons {
    width: 265px;
    display: grid;
    grid-template-columns: 128px 128px;
    gap: 9px;
    margin: 20px auto 16px auto;
}
.return_popup__buttons.single {
    width: 128px;
    display: block;
}

.return_bottom_fixed {
    background: #FFF;
    box-shadow: 0 -2px 12px rgba(0, 0, 0, 0.1);
}
body.fixed_bottom .return_bottom_fixed {
    position: fixed;
    bottom: 84px;
    left: 0;
    right: 0;
}
body.fixed_bottom .return_content {
    padding-bottom: 152px;
}
.return_bottom_fixed__grey {
    display: grid;
    grid-template-columns: 45px 1fr;
    grid-template-rows: 45px;
    gap: 18px;
    background: #f8f8f8;
    padding: 14px 23px 13px 23px;
}
.return_bottom_fixed__grey.two_columns {
    grid-template-columns: auto auto;
}
.btn__small_padding {
    padding: 0 0.2rem;
}
/* .return_bottom_fixed__grey.return {} */
.return_bottom_fixed__grey__sort {
    background: #f2f2f2;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
}
.oneeightzero {
    transform: rotate(180deg);
}

.return_bottom_fixed__white {
    padding: 6px 23px 9px 23px;
    display: grid;
    grid-template-columns: 2fr 2fr 3fr;
    font-size: 12px;
    line-height: 16px;
}
.return_bottom_fixed__white__last {
    display: flex;
    align-items: center;
    font-weight: 700;
    justify-content: flex-end;
}
.return_bottom_fixed__white__last .total_gros {
    display: block;
    font-size: 20px;
    line-height: 27px;
}

.breadcrumbs__steps.one li:nth-child(2) span,
.breadcrumbs__steps.one li:nth-child(3) span {
    display: none;
}
.breadcrumbs__steps.one li:nth-child(1)::before,
.breadcrumbs__steps.two li:nth-child(2)::before,
.breadcrumbs__steps.three li:nth-child(3)::before {
    color: #FFF;
    border-color: var(--maincolor);
    background: var(--maincolor);
}
.wrapper_loading_dots_return {
    align-items: inherit !important;
    height: 36px !important;
}
/* return */

/* return submited */
.return_submited__red {
    padding: 20px;
    color: #FFF;
    background: var(--maincolor);
    border-radius: 5px;
    line-height: 26px;
    margin-bottom: 43px;
}
.return_submited__red h2 {
    margin-bottom: 14px;
}
.return_submited__red__button {
    color: #FFF;
    line-height: 26px;
    font-weight: 600;
}
.return_submited__red__button svg {
    position: relative;
    top: 4px;
    margin-right: 5px;
}
.return_submited__grid {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-bottom: 43px;
}
.return_submited__grid a {
    display: block;
    height: 124px;
    background: #f8f8f8;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    padding: 24px 10px 0 10px;
    color: var(--darkgrey);
    font-weight: 700;
    text-align: center;
}
.return_submited__grid a svg {
    display: block;
    margin: 0 auto 10px auto;
}
.return_submited__grid li .alert {
    display: block;
    padding: 0;
    position: relative;
    bottom: 105px;
    left: calc(50% + 7px);
    width: 24px;
    line-height: 24px;
    font-size: 12px;
    text-align: center;
    color: #FFF;
    background: var(--maincolor);
    border-radius: 50%;
    font-weight: 400;
}

/* return submited */
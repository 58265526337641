.product_page__content {
    padding: 0 23px;
}

.product_page__images {
    /* position: relative; */
    margin: 0 0 20px 0;
}
.product_page__main_image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 255px;
    height: 255px;
    margin: 0 auto 10px auto;
}
.product_page__main_image img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}
.product_page__additional_images {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    overflow: auto;
}
.product_page__additional_images img {
    width: auto;
    height: 65px;
    margin: 0 5px;
    cursor: pointer;
}

.grey_price_area {
    background: #f8f8f8;
    padding: 20px;
    margin-bottom: 25px;
    border-radius: 5px;
}
.grey_price_area__table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #d9d9d9;
    text-align: center;
    font-size: 12px;
    line-height: var(--lineheight);
    margin-bottom: 20px;
}
.grey_price_area__table td {
    padding: 3px 0;
    border-left: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
}
.grey_price_area__table td strike {
    color: #999;
}
.grey_price_area__table tr:first-child td {
    padding-top: 8px;
    font-weight: 700;
    color: #999;
}
.grey_price_area__table tr:last-child td {
    padding-bottom: 9px;
}
.grey_price_area__table__percent {
    display: block;
    font-weight: 400;
    color: var(--darkgrey);
    padding: 8px 0 8px 16px;
    text-align: left;
}
.grey_price_area__table__percent svg {
    position: relative;
    top: -2px;
    margin-right: 5px;
}
.price_cart__area {
    display: grid;
    grid-template-columns: 1fr auto;
    margin-bottom: 20px;
}
.price_cart__area__price {
    display: grid;
}
.price_cart__area__price.has_old_price {
    grid-template-columns: auto 1fr;
    gap: 25px;
}
.price_cart__area__price__old {
    display: none;
    color: #999;
}
.price_cart__area__price.has_old_price .price_cart__area__price__old {
    display: block;
}
.price_cart__area__price__old .price_area__price {
    text-decoration: line-through;
}
.btn-cart {
    width: 120px;
    font-size: 20px;
    line-height: 40px;
}
.btn-cart svg {
    margin-right: 15px;
    top: 8px;
}
.price_area__net {
    display: block;
    font-size: 14px;
    line-height: var(--lineheight);
    font-weight: 700;
    color: #999;
}
.price_area__price {
    display: block;
    font-weight: 700;
    font-size: 20px;
}
.price_area__under_price {
    font-size: 12px;
    line-height: var(--lineheight);
}
.order_history {
    font-size: 12px;
    line-height: 18px;
    color: #999;
    padding: 0 0 0 25px;
    background-position: left top -1px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_2565_11698)'%3E%3Cpath d='M10.5 2.40625H4.5C4.10218 2.40625 3.72064 2.56429 3.43934 2.84559C3.15804 3.12689 3 3.50843 3 3.90625V15.9062C3 16.3041 3.15804 16.6856 3.43934 16.9669C3.72064 17.2482 4.10218 17.4062 4.5 17.4062H13.5C13.8978 17.4062 14.2794 17.2482 14.5607 16.9669C14.842 16.6856 15 16.3041 15 15.9062V6.90625L10.5 2.40625Z' stroke='%23999999' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10.5 2.40625V6.90625H15' stroke='%23999999' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 10.6562H6' stroke='%23999999' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 13.6562H6' stroke='%23999999' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.5 7.65625H6.75H6' stroke='%23999999' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_2565_11698'%3E%3Crect width='18' height='18' fill='white' transform='translate(0 0.90625)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}
.order_history svg {
    position: relative;
    margin-right: 7px;
    top: -1px;
}
.order_history_unit {
    margin-bottom: 5px;
}

.specifications {
    width: 100%;
    border-collapse: collapse;
    font-size: 12px;
    line-height: var(--lineheight);
    border: 1px solid #d9d9d9;
    margin-bottom: 20px;
    border-radius: 5px;
}
.specifications td {
    padding: 6px 0 6px 12px;
}
.specifications tr:first-child td {
    padding-top: 12px;
}
.specifications tr:last-child td {
    padding-bottom: 12px;
}
.specifications strong {
    display: block;
    white-space: nowrap;
}
.specifications_available {
    position: relative;
    top: 2px;
    left: 3px;
    display: inline-block;
    width: 14px;
    height: 14px;
    background: #999;
    border-radius: 50%;
}
.specifications_available.instock {
    background: #00B707;
}
.instock {
    color: #00B707;
}
.specifications_available.low {
    background: #FC6738;
}
.low {
    color: #FC6738;
}
.specifications_available.outofstock {
    background: var(--maincolor);
}
.outofstock {
    color: var(--maincolor);
}

.product_page__banner {
    display: block;
    margin-bottom: 20px;
}
.product_page__banner img {
    width: 100%;
    height: auto;
}

.product_page__accordion {
    border-top: 1px solid #d9d9d9;
    margin-bottom: 40px;
}
.accordion {
    border-bottom: 1px solid #d9d9d9;
}
.accordion__title {
    position: relative;
    font-size: 16px;
    line-height: 50px;
    font-weight: 700;
    cursor: pointer;
    padding: 0 23px;
}
.accordion__title::after {
    content: '';
    position: absolute;
    top: 20px;
    right: 23px;
    width: 7px;
    height: 7px;
    border-bottom: 1px solid;
    border-right: 1px solid;
    transition: var(--transition);
    transform: rotate(45deg);
}
.accordion.active .accordion__title {
    color: var(--maincolor);
}
.accordion.active .accordion__title::after {
    transform: translateY(5px) rotate(225deg);
}
.accordion__content {
    padding: 0 23px;
    overflow: hidden;
    max-height: 0;
    transition: var(--transition);
}
.accordion.active .accordion__content {
    max-height: 2000px;
    transition: var(--transition);
}
.accordion__content p {
    padding-bottom: 1em;
}

.product_page__country {
    position: absolute;
    top: 10px;
    left: 0;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.product_page__booklet,
.product_page__new {
   margin-top: 10px;
}
.product_page__booklet svg,
.product_page__new svg {
    width: 25px;
    height: 25px;
}

.similar_products h2 {
    margin-bottom: 10px;
}

.product_page__placeholder {
    padding: 0 23px;
}
.product_page__placeholder__breadcrumbs {
    padding: 17px 0 10px 0;
    display: flex;
}
.product_page__placeholder__breadcrumbs div {
    width: 1.5rem;
    margin: 0 1rem 0 0;
}
.product_page__placeholder__breadcrumbs div:nth-child(2) {
    width: 6rem;
}
.product_page__placeholder__breadcrumbs div:nth-child(3) {
    width: 12rem;
}
.product_page__placeholder__h1.animated_placeholder {
    height: 3rem;
    margin-bottom: 10px;
}
.product_page__placeholder__main_image.animated_placeholder {
    width: 255px;
    height: 255px;
    margin: 0 auto 10px auto;
}
.product_page__placeholder__images {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
.product_page__placeholder__images .animated_placeholder {
    width: 65px;
    height: 65px;
    margin: 0 5px;
}
.product_page__placeholder__price_area.animated_placeholder {
    height: 200px;
}

.product_page__rating__icons {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 25px;
    margin-bottom: 5px;
}
.product_page__rating__icons__icons {
    display: flex;
    flex-wrap: wrap;
    line-height: 16px;
    align-items: center;
}
.product_page__product__icons {
    display: flex;
    flex-wrap: wrap;
    line-height: 16px;
    height: 40px;
}
.product_page__product__icons > div > label {
    right: inherit;
    left: 35px;
    border-radius: 0px 5px 5px 5px;
}
.product_page__rating__icons__icons svg {
    width: auto;
    height: 24px;
    margin: 0 20px 0 0;
}
.rating_placeholder {
    position: relative;
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
    height: 1em;
    width: 5em;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eratingstarempty%3C/title%3E%3Cg id='ratingstarempty' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='ratingstar' transform='translate(2.600000, 3.000000)' fill-rule='nonzero' stroke='%23FFD800'%3E%3Cpath d='M5.399576,1.12962673 L6.73591523,3.83690167 L9.72494212,4.2737958 L7.56210568,6.38040192 L8.07242585,9.35586926 L5.399576,7.95020691 L2.72672615,9.35586926 L3.23704638,6.38040157 L1.07420549,4.2737958 L4.06323673,3.83690174 L5.399576,1.12962673 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-repeat: repeat-x;
    background-position: left center;
    background-size: 1em;
    margin-right: 4px;

}
.rating_placeholder.on_popup {
    font-size: 25px;
}
.rating_value {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 3.5em;
    height: 1em;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eratingstar%3C/title%3E%3Cg id='ratingstar' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(2.600000, 3.000000)' fill='%23FFD600' fill-rule='nonzero' id='Path'%3E%3Cpolygon points='5.399576 -3.05311332e-16 7.068046 3.380135 10.799146 3.925495 8.099366 6.555085 8.736516 10.270045 5.399576 8.515135 2.062636 10.270045 2.699786 6.555085 1.11022302e-16 3.925495 3.731106 3.380135'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-repeat: repeat-x;
    background-position: left center;
    background-size: 1em;
}
.rating_votes {
    display: inline-block;
    vertical-align: top;
    font-size: 12px;
    line-height: 16px;
    color: #999;
}
.show_rating_popup * {
    pointer-events: none;
}

.product_page__rating_popup {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    visibility: hidden;
}
.product_page__rating_popup.active {
    visibility: visible;
}
.product_page__rating_popup__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .5);
    z-index: 1;
    transition: var(--transition);
    opacity: 0;
}
.product_page__rating_popup.active .product_page__rating_popup__overlay {
    opacity: 1;
}
.product_page__rating_popup__content {
    position: absolute;
    top: 50px;
    left: calc(50% - 164px);
    width: 328px;
    background: #FFF;
    border-radius: 5px;
    text-align: center;
    z-index: 2;
    padding: 50px 21px 35px 21px;
    transition: var(--transition);
    transform: translateY(20px);
    opacity: 0;
}
.product_page__rating_popup.active .product_page__rating_popup__content {
    transform: translateY(0);
    opacity: 1;
}
.product_page__rating_popup__content__product_name {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 25px;
}
.product_page__rating_popup__content__product_image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 92px;
    height: 92px;
    margin: 0 auto 16px auto;
}
.product_page__rating_popup__content__product_image img {
    object-fit: contain;
    height: 100%;
    width: 100%;
}
.product_page__rating_popup__content__register {
    width: 254px;
    font-size: 14px;
    line-height: 26px;
    margin: 0 auto 9px auto;
}
.product_page__rating_popup__content__alert {
    color: #FFF;
    background-color: var(--monolithlightgreen);
    border-radius: 5px;
    font-size: 12px;
    line-height: 16px;
    padding: 11px 15px 14px 49px;
    text-align: left;
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='16' viewBox='0 0 15 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.5 14.25C10.9518 14.25 13.75 11.4518 13.75 8C13.75 4.54822 10.9518 1.75 7.5 1.75C4.04822 1.75 1.25 4.54822 1.25 8C1.25 11.4518 4.04822 14.25 7.5 14.25Z' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.5 10.5V8' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.5 5.5H7.5075' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-position: top 11px left 21px;
    background-repeat: no-repeat;
}
.product_page__rating_popup__content__current_vote_results {
    margin: 22px 0 10px 0;
    font-size: 12px;
    line-height: var(--lineheight);
    color: #999;
}
.product_page__rating_popup__content__current_vote_results_votes {
    font-size: 12px;
    line-height: 16px;
    color: #999;
    margin-top: 5px;
}
.product_page__rating_popup__close {
    position: absolute;
    top: 15px;
    right: 14px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid #999;
}
.product_page__rating_popup__close::before, .product_page__rating_popup__close::after {
    content: '';
    position: absolute;
    top: 5px;
    bottom: 5px;
    width: 1px;
    left: 11px;
    background: #999;
    transform: rotate(45deg);
}
.product_page__rating_popup__close::after {
    transform: rotate(-45deg);
}

.star-rating {
    font-size: 0;
    white-space: nowrap;
    display: block;
    width: 130px;
    height: 25px;
    margin: 0 auto 20px auto;
    overflow: hidden;
    position: relative;
    background-size: contain;
}
.star-rating i {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 20%;
    z-index: 1;
    background-size: contain;
}
.star-rating input {
    appearance: none;
    opacity: 0;
    display: inline-block;
    width: 20%;
    height: 100%;
    margin: 0;
    padding: 0;
    z-index: 2;
    position: relative;
    cursor: pointer;
}
.star-rating input:hover + i,
.star-rating input:checked + i {
    opacity: 1;
}
.star-rating i ~ i {
    width: 40%;
}
.star-rating i ~ i ~ i {
    width: 60%;
}
.star-rating i ~ i ~ i ~ i {
    width: 80%;
}
.star-rating i ~ i ~ i ~ i ~ i {
    width: 100%;
}
.star-rating i {
    background-image: url("data:image/svg+xml,%3Csvg height='42' viewBox='0 0 44 42' width='44' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m20.64401 0 6.3791 12.9232 14.265 2.085-10.322 10.0537 2.436 14.2031-12.7581-6.7093-12.75798 6.7093 2.43598-14.2031-10.32201-10.0537 14.26501-2.085z' style='fill:%23ffd600;stroke:%23ffd600;stroke-width:1.5;fill-rule:evenodd;stroke-linecap:round;stroke-linejoin:round' transform='translate(1.61719 1)'/%3E%3C/svg%3E");
}
.star-rating {
    background-image: url("data:image/svg+xml,%3Csvg fill='none' height='42' viewBox='0 0 44 42' width='44' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m22.2612 1 6.3791 12.9232 14.265 2.085-10.322 10.0537 2.436 14.2031-12.7581-6.7093-12.75798 6.7093 2.43598-14.2031-10.32201-10.0537 14.26501-2.085z' stroke='%23ffd600' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/svg%3E");
}


.all_about_price {
    background: #f8f8f8;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 20px;
}
.all_about_price_with_discount {
    position: relative;
    padding-top: 35px;
}
.all_about_price_discount {
    position: absolute;
    top: 20px;
    left: 75px;
    line-height: 20px;
    background: var(--monolithyellow);
    color: #000;
    font-weight: 700;
    padding: 0 3px;
    font-size: 1rem;
    border-radius: 5px;
}
.all_about_price__three {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    gap: 3px;
    margin-bottom: 20px;
}
.all_about_price__three__single {
    background: #FFF;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
}
.aap_single_old {
    color: #d9d9d9;
}
.aap_unit {
    padding: 5px 10px;
}
.aap_unit__border_bottom {
    border-bottom: 1px solid #d9d9d9;
}
.aap_price {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: var(--lineheight);
}
.aap_capacity {
    font-size: .75rem;
    line-height: var(--lineheight);
}
.aap_vat_gros {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    line-height: var(--lineheight);
    white-space: nowrap;
}
.aap_vat_gros strong {
    color: #999;
    font-weight: 700;
}
.aap_single_old .aap_vat_gros strong {
    color: #d9d9d9;
}
.aap_rrp {
    padding-top: 3px;
    font-size: .75rem;
    line-height: var(--lineheight);
}
.aap_rrp_value {
    font-weight: 700;
    font-size: .875rem;
    line-height: 24px;
}
.app_mm {
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;
}
.app_mm svg {
    margin: 0 3px 0 -3px;
}
.aap_history_cart {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 10px;
}
.aap_history {
    font-size: 12px;
    line-height: var(--lineheight);
    padding: 0 0 0 23px;
    background-position: top 11px left;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_2565_11698)'%3E%3Cpath d='M10.5 2.40625H4.5C4.10218 2.40625 3.72064 2.56429 3.43934 2.84559C3.15804 3.12689 3 3.50843 3 3.90625V15.9062C3 16.3041 3.15804 16.6856 3.43934 16.9669C3.72064 17.2482 4.10218 17.4062 4.5 17.4062H13.5C13.8978 17.4062 14.2794 17.2482 14.5607 16.9669C14.842 16.6856 15 16.3041 15 15.9062V6.90625L10.5 2.40625Z' stroke='%23999999' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10.5 2.40625V6.90625H15' stroke='%23999999' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 10.6562H6' stroke='%23999999' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 13.6562H6' stroke='%23999999' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.5 7.65625H6.75H6' stroke='%23999999' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_2565_11698'%3E%3Crect width='18' height='18' fill='white' transform='translate(0 0.90625)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}
.aap_no_history {
    display: block;
    height: 40px;
    display: flex;
    align-items: center;
}
.aap_history__inner.only_one {
    min-height: 40px;
    display: flex;
    align-items: center;
}
.aap_history__inner div:not(:first-child) {
    margin-top: 5px;
}
.flex_end {
    display: flex;
    justify-content: flex-end;
}
.button_favorite_details {
    position: absolute;
    right: 23px;
}

/* start product details */
.product_details__wrapper,
.change_account__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    visibility: visible;
    overflow: auto;
}
.product_details__popup,
.change_account__popup {
    background: #FFF;
    padding: 14px 10px 27px 10px;
}
.change_account__popup {
    height: 100vh;
}
.change_account__wrapper_content {
    padding-top: 25px;
}
.change_account__content {
    padding: 0 var(--sidagap);
}
.change_account__content_title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.change_account__content :last-child div {
    margin-top: 20px;
}
.product_details__wrapper .order_history_popup__wrapper.active .order_history_popup {
    transform: translate(-50%, -75%) scale(1);
}
/* end product details */
@keyframes loading {
    0%, 100% {
      opacity: 0;
    }
    25%, 75% {
      opacity: 1;
    }
  }
.loadingDots {
    display: inline-block;
    /* font-size: 24px; */
    text-align: center; 
    margin-left: 10px;
}
.loadingDot {
    display: inline-block;
    width: 3px;
    height: 3px;
    background-color: #fff;
    border-radius: 50%;
    margin: 0 4px;
    animation: loading 1s infinite;
}
.loadingDot:nth-child(2) {
    animation-delay: 0.25s;
}
.loadingDot:nth-child(3) {
    animation-delay: 0.5s;
}
.marketing_content {
    padding: 0 23px;
}

.marketing_content h1 {
    margin-bottom: 28px;
}
.marketing_content h2 {
    margin-bottom: 28px;
}

.marketing_grid {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-bottom: 36px;
}
.marketing_grid a {
    display: block;
    padding: 20px 20px 0 20px;
    height: 124px;
    background: #F8F8F8;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    color: var(--darkgrey);
    text-align: center;
    line-height: 20px;
    font-weight: 700;
}
.marketing_grid a:hover,
.marketing_grid a:active {
    color: var(--maincolor);
    border-color: var(--maincolor);
}
.marketing_grid a svg {
    display: block;
    margin: 0 auto 10px auto;
}
.marketing_grid a:hover svg path ,
.marketing_grid a:active svg path {
    stroke: var(--maincolor);
}

.marketing_banner {
    display: block;
    margin-bottom: 20px;
    cursor: pointer;
}
.marketing_banner img {
    width: 100%;
    height: auto;
}
.process_content {
    padding: 0 23px;
}
.process_content h1 {
    margin-bottom: 17px;
}

.process_info {
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
}
.process_info.last {
    margin-bottom: 40px;
}
.process_info h2 {
    color: var(--maincolor);
    margin-bottom: 14px;
}
.process_info__table {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
    line-height: 26px;
}
.process_info__table .animated_placeholder{
   height: 1.15rem;
}
.process_info__table>div:first-child {
    font-weight: 600;
}
.process_info .process_info__table:last-child {
    margin-bottom: 0;
}

.process_documents {
    margin-bottom: 20px;
}
.process_documents h2 {
    margin-bottom: 33px;
}
.process_document {
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    margin-bottom: 10px;
}
.process_document:nth-child(even) {
    background: #f8f8f8;
}
.process_document:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.process_document__first {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 7px 46px 6px 21px;
    font-size: 12px;
    line-height: 16px;
    gap: 2px;
    color: #999;
}
.process_document__first strong {
    color: var(--darkgrey);
    font-size: 14px;
    line-height: 26px;
}
.process_document__first__type {
    display: inline-block;
    color: #FFF;
    background: #999;
    line-height: 24px;
    padding: 0 14px;
    border-radius: 5px;
}
.process_document__first__button {
    position: absolute;
    width: 32px;
    height: 32px;
    right: 8px;
    top: 19px;
}
.process_document__first__button::after {
    content: '';
    position: absolute;
    top: 10px;
    left: 12px;
    width: 10px;
    height: 10px;
    color: #999;
    border-bottom: 1px solid;
    border-right: 1px solid;
    transform: rotate(45deg);
}
.process_document.active .process_document__first__button::after {
    transform: translateY(6px) rotate(225deg);
}

.process_document__second {
    border-top: 1px solid #d9d9d9;
    padding: 12px 12px 17px 12px;
    display: none;
}
.process_document.active .process_document__second {
    display: block;
}
.process_document__second__info {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    line-height: 16px;
    color: #999999;
    padding: 0 8px;
    margin-bottom: 14px;
}
.process_document__second__buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 7px;
}
.process_document__second__button {
    background: #F2F2F2;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    color: var(--darkgrey);
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    padding: 7px;
}
.process_document__second__button svg {
    display: block;
    margin: 0 auto 5px auto;
}
.all_products {
    padding: 0 23px;
}
.products_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 145px;
}
.products_grid.nopadding {
    padding-bottom: 0;
}
.similar_products .products_grid {
    grid-template-columns: 1fr;
}
.similar_products .products_grid .ais-Hits-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.products_grid.list {
    grid-template-columns: 1fr;
}
.products_grid__product {
    position: relative;
    padding: 45px 17px 17px 17px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    overflow: hidden;
}
.products_grid.list .products_grid__product {
    padding-top: 38px;
}
.products_grid__product:hover, .products_grid__product.incart {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
.product_icons {
    position: absolute;
    top: 50px;
    right: 18px;
    pointer-events: none;
}
.product_icons svg {
    width: 18px;
    height: auto;
    margin-bottom: 10px;
}
.image_area {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 108px;
}
.image_area img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}
.product_link {
    display: block;
    color: var(--darkgrey);
    line-height: 19px;
    height: 38px;
    overflow: hidden;
    margin-bottom: 6px;
}
.image_info_area {
    margin-bottom: 10px;
}
.products_grid.list .image_info_area {
    display: grid;
    grid-template-columns: 108px 1fr;
    gap: 0 12px;
}
.brand_area {
    font-size: 12px;
    line-height: var(--lineheight);
    height: var(--lineheight);
    overflow: hidden;
    margin-bottom: 10px;
    /*
    white-space: nowrap;
    text-overflow: ellipsis;
    width: calc(100% - 21px);
    */
}
.brand_area svg {
    position: relative;
    margin-right: 5px;
    top: -1px;
    margin-bottom: 10px;
}
.products_grid.grid .brand_area {
    position: absolute;
    top: 30px;
    left: 17px;
    right: 7px;
    color: #999;
    pointer-events: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: var(--lineheight);
}
.products_grid.grid .brand_area svg {
    display: none;
}
.item_id_area,
.item_id_area_truc {
    position: absolute;
    top: 14px;
    left: 17px;
    font-size: 12px;
    line-height: var(--lineheight);
    color: #999;
    pointer-events: none;
}
.item_id_area_truc {
    top: 7px;
    right: 17px;
    left: inherit;
    pointer-events: visible;
}
.item_id_area_truc svg {
    height: 22px;
    width: 22px;
}
.item_id_area_truc > div {
    position: absolute;
    bottom: inherit;
    top: 0px;
    right: 29px;
    z-index: 15;
    padding: 5px 10px 8px 15px;
    border-radius: 5px 0px 5px 5px;
    border: 1px solid var(--400, #D9D9D9);
    background: #FFF;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.10);
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    color: #727272;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.item_id_area_truc > div > div {
    width: max-content;
}
.item_id_area_truc_view_list > div {
    top: -1px;
    right: 0px;
}
.item_id_area_truc_view_list > div > div {
    width: auto;
}
.item_truc__product_page {
    position: relative;
    right: auto;
    left: 30px;
    top: 3px;
}
.item_truc__product_page > div {
    right: 24px;
    border-radius: 5px 0px 5px 5px;
}
.item_truc__product_page svg {
    height: 36px;
    width: 36px;
}
.products_grid.list .item_id_area {
    left: 36px;
}
.uni_stock_area {
    display: grid;
    grid-template-columns: 1fr auto;
    font-size: 12px;
    line-height: var(--lineheight);
}
.products_grid.list .uni_stock_area {
    grid-template-columns: 1fr;
    gap: 10px 0;
}
.stock_area.instock {
    color: #00B707;
}
.stock_area.outofstock {
    color: var(--maincolor);
}
.stock_area.low {
    color: #FC6738;
}
.stock_area::before {
    content: '';
    position: relative;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #999;
    margin-right: 6px;
}
.products_grid.list .stock_area::before {
    margin-right: 11px;
}
.stock_area.instock::before {
    background: #00B707;
}
.stock_area.outofstock::before {
    background: var(--maincolor);
}
.stock_area.low::before {
    background: #FC6738;
}
.uni_area {
    display: flex;
}
.uni_area svg {
    margin-right: 5px;
}
.buttons_area {
    display: flex;
    justify-content: space-between;
}
.prices_buttons_area {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
}
.products_grid.list .prices_buttons_area {
    grid-template-columns: 1fr 140px;
}
.prices_area {
    display: flex;
    align-items: center;
}
.prices_area__price {
    font-size: 16px;
    font-weight: 700;
    line-height: var(--lineheight);
    margin-right: 1em;
}
.prices_area__price.is_discount_true {
    color: var(--maincolor);
}
.prices_area__little {
    font-size: 12px;
    line-height: var(--lineheight);
    color: #999;
}
.buttons_area__button {
    width: 40px;
    height: 40px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.buttons_area__button.false .heartwhite {
    display: block;
}
.buttons_area__button.true .heartwhite {
    display: none;
}
.buttons_area__button.false .heartred {
    display: none;
}
.buttons_area__button.true .heartred {
    display: block;
}
.buttons_area__button.cart {
    background: var(--maincolor);
    border-color: var(--maincolor);
}
.buttons_area__button svg {
    pointer-events: none;
}
.buttons_area__button.products_is_in_cart {
    color: #FFF;
    background: var(--monolithlightgreen);
    border-color: var(--monolithlightgreen);
}
.country_badge {
    position: absolute;
    top: 52px;
    left: 17px;
    width: 15px;
    height: 15px;
    background-position: center;
    background-color: #f8f8f8;
    background-size: cover;
    border-radius: 50%;
    pointer-events: none;
    border: 0.5px solid #999;
}
.wrapper__info_product_icons {
    position: relative;
    top: 50px;
    right: 29px;
}
.wrapper__info_prod_icons_not {
    right: 9px;
}
.country_badge_1 {
    width: 24px;
    height: 24px;
    background-position: center;
    background-color: #f8f8f8;
    background-size: cover;
    border-radius: 50%;
    pointer-events: none;
    border: 0.5px solid #999;
}
.products_grid.list .country_badge {
    top: 40px;
    left: 36px;
}
.new_badge {
    position: absolute;
    top: 77px;
    left: 17px;
    width: 15px;
    height: 15px;
    pointer-events: none;
}
.products_grid.list .new_badge {
    top: 66px;
    left: 36px;
}
.booklet_badge {
    position: absolute;
    top: 102px;
    left: 17px;
    width: 15px;
    height: 15px;
    pointer-events: none;
}
.products_grid.list .booklet_badge {
    top: 91px;
    left: 36px;
}
.discount_badge {
    position: absolute;
    top: 7px;
    right: 17px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    color: #000;
    background: #FFD600;
    padding: 0 2px;
    border-radius: 5px;
}
.discount_badge_if_suplier {
    right: 50px;
}

.sorting_panel {
    position: fixed;
    bottom: 84px;
    left: 0;
    right: 0;
    z-index: 90;
}
.sorting_panel__content {
    position: relative;
    display: grid;
    grid-template-columns: 48px 48px 1fr 48px 48px;
    gap: 0 10px;
    padding: 3px 23px;
    background: #FFF;
    box-shadow: 0 -2px 12px rgba(0, 0, 0, 0.1);
    z-index: 100;
}
.sorting_panel__button {
    width: 48px;
    height: 48px;
    background: #F2F2F2;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
}
.sorting_panel__button svg,
.sorting_panel__button span {
    pointer-events: none;
}
.sorting_panel__info {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 18px;
}
.sort_kind, .how_show {
    color: #FFF;
    background: var(--maincolor);
}
.sort_kind span, .sort_kind svg {
    display: none;
}
.sort_kind.all .all,
.sort_kind.allitems .all {
    display: inline;
}
.sort_kind {
    display: flex;
    justify-content: center;
    align-items: center;
}
.sort_kind svg path,
.sort_kind svg rect,
.sort_kind svg line {
    stroke: #FFF;
}
.sort_kind.favorites .show_heart,
.sort_kind.new .show_tag,
.sort_kind.discount .show_discount,
.sort_kind.history .show_refresh,
.sort_kind.purchased .show_refresh,
.sort_kind.booklet .show_book {
    display: block;
}
.how_show svg {
    pointer-events: none;
    display: none;
}
.how_show.show_grid svg.show_grid,
.how_show.show_list svg.show_list {
    display: inline;
}
.sorting_panel__types, .sorting_panel__stock, .sorting_panel__sort {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
    z-index: 95;
    background: #FFF;
    padding: 20px 23px;
    display: flex;
    justify-content: space-between;
    border-radius: 5px 5px 0 0;
    transition: var(--transition);
    transform: translateY(100%);
}
.sorting_panel__types.active, .sorting_panel__stock.active, .sorting_panel__sort.active {
    transform: translateY(0);
}
.sorting_panel__stock {
    display: block;
    padding: 24px 23px;
    text-align: center;
}
.sorting_panel__stock label {
    margin: 0 14px;
}
.sorting_panel__stock input {
    margin: 0 10px 0 0;
}
.sorting_panel__types__button {
    width: 48px;
    height: 48px;
    line-height: 46px;
    background: #F2F2F2;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    color: var(--darkgrey);
}
.sorting_panel__types__button.active {
    color: #FFF;
    background: var(--maincolor);
}
.sorting_panel__types__button svg {
    position: relative;
    top: 16px;
    pointer-events: none;
}
.sorting_panel__types__button svg path {
    stroke: #999;
}
.sorting_panel__types__button.active svg rect,
.sorting_panel__types__button.active svg line,
.sorting_panel__types__button.active svg path {
    stroke: #FFF;
}
.sorting_panel__sort {
    padding: 0 23px;
    display: block;
}
.sorting_panel__sort ul {
    list-style: none;
}
.sorting_panel__sort ul li {
    line-height: 66px;
    border-bottom: 1px solid #d9d9d9;
    cursor: pointer;
}
.sorting_panel__sort ul li.active {
    font-weight: 700;
}
.sorting_panel__sort ul li:last-child {
    border-bottom: 0 none;
}
.sorting_panel__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 80;
    transition: var(--transition);
    visibility: hidden;
    opacity: 0;
}
.sorting_panel__overlay.active {
    visibility: visible;
    opacity: 1;
}

.aside_filters {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    visibility: hidden;
}
.aside_filters.active {
    visibility: visible;
}
.aside_filters__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    transition: var(--transition);
    opacity: 0;
    z-index: 10;
}
.aside_filters.active .aside_filters__overlay {
    opacity: 1;
}
.aside_filters__content {
    position: absolute;
    top: 41px;
    bottom: 44px;
    left: 0;
    right: 44px;
    background: #FFF;
    z-index: 20;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.4);
    border-radius: 0 5px 5px 0;
    transition: var(--transition);
    transform: translateX(calc(-100vw + 44px));
}
.aside_filters.active .aside_filters__content {
    transform: translateX(0);
}
.aside_filters__content__button__show_hide {
    position: absolute;
    top: calc(50vh + 20px);
    right: -44px;
    width: 44px;
    height: 44px;
    background-color: var(--maincolor);
    border-radius: 0 5px 5px 0;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.875 2.28223H2.125L7.625 8.78598V13.2822L10.375 14.6572V8.78598L15.875 2.28223Z' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    z-index: 1;
    visibility: visible;
    transition: transform 0.5s;
}
.button__celar_filter_products {
    top: 10vh;
    right: 0;
    border-radius: 5px 0 0 5px;
    background-image: none;
}
.aside_filters__content__button__show_hide.display_none{
    transform: translateX(-50px)
}
.aside_filters__content__button__show_hide::after {
    content: attr(data-count);
    position: absolute;
    top: 10px;
    right: 7px;
    width: 15px;
    line-height: 15px;
    font-size: 12px;
    color: var(--maincolor);
    background: #FFF;
    border-radius: 50%;
    text-align: center;
}
.aside_filters__content__button__show_hide[data-count="0"]::after {
    display: none;
}
.aside_filters__content__button {
    position: absolute;
    width: 176px;
    height: 30px;
    line-height: 30px;
    top: 220px;
    right: -103px;
    color: #FFF;
    background: #999;
    text-align: center;
    transform: rotate(-90deg);
    border-radius: 0 0 5px 5px;
    cursor: pointer;
    visibility: visible;
    z-index: 1;
}
.aside_filters__content__inner {
    padding: 20px 0 20px 0;
    max-height: 100%;
    overflow-y: auto;
}
.aside_filters__content__inner hr {
    height: 1px;
    border: 0 none;
    background: #d9d9d9;
    margin: 10px -20px 10px -23px;
}
.filter_arrow {
    position: absolute;
    right: 11px;
    top: 5px;
    transform: rotate(-90deg);
}
.filter_arrow.alt {
    transform: rotate(90deg);
}
.aside_filters__content__button__count {
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    line-height: 30px;
    color: #FFF;
    background: var(--maincolor);
    transform: rotate(90deg);
    border-radius: 0 5px 5px 0;
}
.aside_filters__content__inner__active_filters {
    font-size: 16px;
    font-weight: 700;
    padding: 0 20px 0 23px;
    margin-bottom: 10px;
}
.aside_filters__content__inner__active_filters__list {
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px 18px 23px;
}
.ais-CurrentRefinements-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}
.ais-CurrentRefinements-label {
    display: none;
}
.aside_filters__selected_filter {
    position: relative;
    font-size: 12px;
    line-height: 26px;
    background: #F2F2F2;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    padding: 0 35px 0 10px;
    margin: 0 4px 7px 0;
}
.aside_filters__selected_filter[data-count="0"] {
    display: none;
}
.aside_filters__selected_filter::before,
.aside_filters__selected_filter::after {
    content: '';
    position: absolute;
    top: 7px;
    right: 12px;
    width: 1px;
    height: 12px;
    background: var(--darkgrey);
    transform: rotate(45deg);
}
.aside_filters__selected_filter::after {
    transform: rotate(-45deg);
}
.aside_filters__selected_filter.first {
    color: #fff;
    background: #999;
    border-color: #999;
}
.aside_filters__selected_filter.first::before,
.aside_filters__selected_filter.first::after {
    background: #FFF;
}

.filter_unit {
    position: relative;
    padding: 13px 20px 13px 23px;
}
.filter_unit.collapsed {
    max-height: 48px;
    overflow-y: hidden;
}
.filter_unit.collapsed .filter_unit__search_wrapper,
.filter_unit.collapsed .filter_placeholders,
.filter_unit.collapsed .filter_remove_selections {
    display: none;
}
.filter_unit::before, .filter_unit:last-child::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: #d9d9d9;
}
.filter_unit:last-child::after {
    top: auto;
    bottom: 0;
}
.filter_unit__header {
    position: relative;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: var(--maincolor);
    margin-bottom: 10px;
    cursor: pointer;
}
.filter_unit__header::after {
    content: '';
    position: absolute;
    top: 8px;
    right: 10px;
    width: 8px;
    height: 8px;
    color: var(--maincolor);
    border-bottom: 2px solid;
    border-right: 2px solid;
    transform: rotate(-135deg);
}
.filter_unit.collapsed .filter_unit__header::after {
    transform: rotate(45deg);
}
.filter_unit__search {
    width: 100%;
    line-height: 33px;
    padding: 0 35px 0 15px;
    font-size: 16px;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    background-position: center right 10px;
    background-repeat: no-repeat;
    margin-bottom: 20px;
    background-image: url("data:image/svg+xml,%3Csvg fill='none' height='18' viewBox='0 0 19 18' width='19' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%23606060' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'%3E%3Cpath d='m8.29409 15.8315c3.89841 0 7.05881-3.1603 7.05881-7.05882 0-3.89847-3.1604-7.05881-7.05881-7.05881-3.89847 0-7.0588 3.16034-7.0588 7.05881 0 3.89852 3.16033 7.05882 7.0588 7.05882z'/%3E%3Cpath d='m18 16.714-3.8382-3.4118'/%3E%3C/g%3E%3C/svg%3E");
}
.filter_remove_selections {
    width: 100%;
    position: relative;
    color: var(--maincolor);
    padding: 0 0 0 26px;
    background-position: left center;
    background-repeat: no-repeat;
    text-align: left;
    background-image: url("data:image/svg+xml,%3Csvg fill='none' height='19' viewBox='0 0 18 19' width='18' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%239f0027' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'%3E%3Cpath d='m13.5 5.11377-9 9.00003'/%3E%3Cpath d='m4.5 5.11377 9 9.00003'/%3E%3C/g%3E%3C/svg%3E");
}
.filter_remove_selections[data-count="0"] {
    display: none;
}
.filter_remove_selections::after {
    content: attr(data-count);
    position: absolute;
    top: 0;
    right: 0;
    width: 18px;
    line-height: 18px;
    font-size: 10px;
    font-weight: 700;
    text-align: center;
    color: #FFF;
    background: var(--maincolor);
    border-radius: 50%;
}
.filter_unit__label {
    position: relative;
    display: block;
    padding-right: 22px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.filter_unit__label.brandschecked.false:not(.brands), .filter_label[data-count="0"] {
    pointer-events: none;
    cursor: not-allowed;
    cursor: no-drop;
    opacity: .5;
}
.filter_unit__label::after,
.filter_unit__count {
    content: attr(data-count);
    position: absolute;
    top: 2px;
    right: 0;
    width: 18px;
    line-height: 18px;
    font-size: 10px;
    text-align: center;
    color: #FFF;
    background: var(--darkgrey);
    border-radius: 50%;
    font-weight: 700;
}
.filter_placeholders>* {
    position: relative;
    height: 1.5rem;
    background: #f2f2f2;
    border-radius: 3px;
    margin: 1em 0;
    overflow: hidden;
}
.filter_placeholders>*::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: content-placeholder-animation 1s linear infinite;
    background: linear-gradient(to right, rgba(15, 15, 15, 0) 0%, rgba(219, 219, 219, 0.4) 50%, rgba(255, 255, 255, 0) 100%);
}
.filter_unit__list {
    list-style: none;
    max-height: 180px;
    overflow-y: auto;
}
.filter_unit__list.normal_height {
    max-height: none;
}
.filter_unit__list li {
    padding: 2px 0 2px 3px;
}
.filter_checkbox {
    margin: 0 10px 0 0;
}

/* cart overlay */
.cart_overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.9);
    z-index: 10;
    transition: var(--transition);
    visibility: hidden;
    transform: translateX(100%);
}
.cart_overlay.active {
    visibility: visible;
    transform: translateX(0);
}
.cart_overlay .item_id_area {
    color: #FFF;
    left: 21px;
}
.cart_overlay__brand_area {
    position: absolute;
    top: 30px;
    left: 21px;
    color: #FFF;
    font-size: 12px;
    line-height: var(--lineheight);
}
.products_grid.list .cart_overlay__brand_area {
    left: 36px;
}
.cart_overlay__to_cart_text {
    position: absolute;
    top: 70px;
    left: 23px;
    font-size: 14px;
    line-height: 26px;
    font-weight: 700;
    color: #FFF;
}
.products_grid.list .cart_overlay__to_cart_text {
    left: 36px;
}
.cart_overlay__product_name {
    position: absolute;
    top: 111px;
    left: 23px;
    right: 23px;
    color: #FFF;
    line-height: var(--lineheight);
}
.products_grid.list .cart_overlay__product_name {
    left: 36px;
    right: 72px;
}
.cart_overlay__buttons_input {
    position: absolute;
    left: 23px;
    right: 23px;
    bottom: 24px;
    height: 45px;
    display: grid;
    grid-template-columns: 45px 1fr 45px;
}
.cart_overlay__buttons_input div {
    margin: 0;
    height: 45px;
}
.products_grid.list .cart_overlay__buttons_input {
    left: auto;
    width: 140px;
}
.product_button__minus, .product_button__plus, .product_button__trash {
    color: var(--darkgrey);
    background: #FFF;
    border: 0 none;
    font-size: 20px;
    line-height: 40px;
    border-radius: 5px 0 0 5px;
    border-right: 1px solid #d9d9d9;
}
.product_button__plus {
    color: #FFF;
    background: var(--monolithlightgreen);
    border-radius: 0 5px 5px 0;
    border-right: 0 none;
    border-left: 1px solid #d9d9d9;
}
.product_button__trash svg {
    position: relative;
    top: 11px;
}
.product_amount_input {
    line-height: 40px;
    width: 100%;
    border: 0 none;
    text-align: center;
}
.cart_overlay__close {
    position: absolute;
    bottom: 82px;
    right: 23px;
    width: 29px;
    height: 29px;
    background: #FFF;
    border-radius: 50%;
}
.cart_overlay__close::before,
.cart_overlay__close::after {
    content: '';
    position: absolute;
    top: 7px;
    bottom: 7px;
    left: 14px;
    width: 1px;
    background: var(--maincolor);
    transform: rotate(45deg);
}
.cart_overlay__close::after {
    transform: rotate(-45deg);
}
/* cart overlay */

/* product placeholder */
.products_grid__product__placeholder {
    position: relative;
    padding: 17px;
    background: #FFF;
    border: 1px solid #D9D9D9;
}
.content_placeholder {
    height: 1rem;
    margin-bottom: 1rem;
    position: relative;
    background: #f2f2f2;
    border-radius: 3px;
    overflow: hidden;
}
.content_placeholder::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: content-placeholder-animation 1s linear infinite;
    background: linear-gradient(to right, rgba(15, 15, 15, 0) 0%, rgba(219, 219, 219, 0.4) 50%, rgba(255, 255, 255, 0) 100%);
}
@keyframes content-placeholder-animation {
    0% {
      transform: translateX(-50%);
    }
    100% {
      transform: translateX(300%);
    }
}
.product_placeholder__itemid {
    width: 50%;
}
.product_placeholder__image {
    width: 108px;
    height: 108px;
    margin-left: auto;
    margin-right: auto;
}
.product_placeholder__buttons {
    display: flex;
    justify-content: space-between;
}
.product_placeholder__button {
    width: 40px;
    height: 40px;
    margin-bottom: 0;
}
/* product placeholder */

/* Meilisearch */
.ais-SearchBox-form {
    position: relative;
    margin-bottom: 20px;
}
.ais-SearchBox-form input[type="search"] {
    width: 100%;
    line-height: 38px;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    padding: 0 40px 0 15px;
    font-size: 16px;
}
.ais-SearchBox-form button[type="submit"] {
    position: absolute;
    top: 12px;
    right: 12px;
}
.ais-SearchBox-form button[type="submit"] svg {
    width: 16px;
    height: 16px;
}
.ais-SearchBox-form button[type="reset"] {
    display: none;
}
.ais-ClearRefinements {
    display: inline-block;
}
.ais-ClearRefinements-button {
    position: relative;
    font-size: 12px;
    line-height: 26px;
    border: 1px solid #999;
    border-radius: 5px;
    padding: 0 35px 0 10px;
    margin: 0 4px 7px 0;
    color: #fff;
    background: #999;
}
.ais-ClearRefinements-button::before,
.ais-ClearRefinements-button::after {
    content: '';
    position: absolute;
    top: 7px;
    right: 12px;
    width: 1px;
    height: 12px;
    background: #FFF;
    transform: rotate(45deg);
}
.ais-ClearRefinements-button::after {
    transform: rotate(-45deg);
}
.ais-ClearRefinements-button.ais-ClearRefinements-button--disabled {
    display: none;
}
.ais-CurrentRefinements-category {
    display: inline-block;
    position: relative;
    font-size: 12px;
    line-height: 26px;
    background: #F2F2F2;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    padding: 0 35px 0 10px;
    margin: 0 4px 7px 0;
}
.ais-CurrentRefinements-delete {
    position: absolute;
    top: 4px;
    right: 14px;
}
/* Meilisearch */
.hidden_allproducts {
    display: none;
}
.container_icon_product {
    position: relative;
    display: block;
    pointer-events: visible;
    cursor: pointer;
}
.container_icon_product label {
    position: absolute;
    bottom: 6px;
    right: 21px;
    background-color: white; 
    padding: 5px 10px 5px 17px;
    border-radius: 5px 5px 0px 5px;
    border: 1px solid var(--400, #D9D9D9);
    background: #FFF;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.10);
    transition: var(--transition);
}
.container_icon_product_up label {
    bottom: 17px;
    right: inherit;
    border-radius: 5px 5px 5px 0px;
}
.container_icon_product_down label {
    bottom: inherit;
    right: inherit;
    top: 17px;
    border-radius: 0px 5px 5px 5px;
}
.put_to_cart.waiting .spinner_ring {
    display: inline;
}
.put_to_cart.normal .spinner_ring {
    display: none;
}
.spinner_ring,
.spinner_ring_red {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0.3);
  }
.spinner_ring div,
.spinner_ring_red div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: spinner_ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}
.spinner_ring_red div{
    border: 8px solid var(--maincolor);
    border-color: var(--maincolor) transparent transparent transparent;
}
.spinner_ring div:nth-child(1),
.spinner_ring_red div:nth-child(1) {
    animation-delay: -0.45s;
}
.spinner_ring div:nth-child(2),
.spinner_ring_red div:nth-child(2) {
    animation-delay: -0.3s;
}
.spinner_ring div:nth-child(3),
.spinner_ring_red div:nth-child(3) {
    animation-delay: -0.15s;
}
.scroll_button_top {
    position: fixed;
    top: calc(56vh);
    right: 0px;
    color: #FFF;
    background: var(--maincolor);
    font-weight: 700;
    z-index: 99;
    padding: 8px 5px 8px 5px;
    border-radius: 5px 0px 0px 5px;
    cursor: pointer;
    line-height: 1em;
    transition: 1.2s ease 0s;
    transform: translateX(110px);
    text-align: center;
}
.cart_icon_round svg circle,
.cart_icon_round svg path {
    stroke: var(--maincolor);
}
.scroll_button_top svg{
    background: white;
    border-radius: 50%;
}
.scroll_button_top_active {
    transition: 1.5s ease 0s;
    transform: translateX(0px);
}
.scroll_button_top_text {
    text-align: center;
    font-weight: 400;
    font-size: .875rem;
    line-height: 1em;
}

@keyframes spinner_ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
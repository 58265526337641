/* oreders */

.orders_content {
    padding: 0 23px;
}

.orders {
    margin-bottom: 20px;
}
.order {
    background: #FFF;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: var(--lineheight);
    color: #999;
}
.order:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
.orders_content__wrapper_title{
    display: flex;
    gap: 10px;
}
.order:nth-child(even) {
    background: #f8f8f8;
}
.order strong {
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
    color: var(--darkgrey);
    white-space: nowrap;
}

.order__first {
    position: relative;
    padding: 12px 46px 7px 21px;
    display: grid;
    gap: 5px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto;
}
.order__first__button {
    position: absolute;
    width: 32px;
    height: 32px;
    right: 8px;
    top: 19px;
}
.order__first__button::after {
    content: '';
    position: absolute;
    top: 10px;
    left: 12px;
    width: 10px;
    height: 10px;
    color: #999;
    border-bottom: 1px solid;
    border-right: 1px solid;
    transform: rotate(45deg);
}
.order.active .order__first__button::after {
    transform: translateY(6px) rotate(225deg);
}
.order__first__status {
    display: inline-block;
    color: #FFF;
    background: #999;
    line-height: 24px;
    padding: 0 8px;
    border-radius: 5px;
}
.order__first__status.pending {
    background: var(--monolithorange);
}
.order__first__status.delivered {
    background: var(--monolithgreen);
}

.order__second {
    display: none;
    border-top: 1px solid #d9d9d9;
    padding: 11px 13px 15px 13px;
}
.order.active .order__second {
    display: block;
}
.order__second__two_values {
    margin-right: 12px;
}
.order__second__three_columns {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}
.order__second__buttons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 7px;
}
.order__second__button {
    padding: 7px 7px 5px 7px;
    color: var(--darkgrey);
    background: #f2f2f2;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    text-align: center;
}
.order__second__button svg {
    display: block;
    margin: 0 auto 5px auto;
}
.order__second__info {
    padding: 0 8px;
}

/* oreders */



/* oreder */

.order_page__info {
    padding: 20px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    margin-bottom: 23px;
}
.order_page__info h2 {
    color: var(--maincolor);
    margin-bottom: 14px;
}
.order_page__info__table {
    display: flex;
    justify-content: space-between;
    line-height: 26px;
    margin-bottom: 14px;
}
.order_page__info__table>div:first-child {
    font-weight: 600;
}
.order_page__info__buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 13px;
}
.order_page__info__button {
    color: var(--darkgrey);
    background: #F2F2F2;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    padding: 7px;
    font-size: 12px;
}
.order_page__info__button svg {
    display: block;
    margin: 0 auto 5px auto;
}
.order_page__info_item_additionalnote > span {
    position: absolute;
    bottom: inherit;
    top: inherit;
    right: 29px;
    z-index: 15;
    padding: 5px 10px 8px 15px;
    border-radius: 5px;
    border: 1px solid var(--400, #D9D9D9);
    background: #FFF;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.10);
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    color: #727272;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 84%;
}
.status.active {
    color: var(--monolithgreen);
}
.order__first__status.active {
    background: var(--monolithgreen);
}
.status.draft {
    color: var(--monolithorange);
}
.order__first__status.draft {
    background: var(--monolithorange);
}
.status.inactive {
    color: var(--maincolor);
}
.order__first__status.inactive {
    background: var(--maincolor);
}

.order_page__products {
    margin-bottom: 20px;
}
.order_page__product {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    margin-bottom: 10px;
}
.order_page__product__first {
    padding: 7px;
    display: grid;
    grid-template-columns: 55px 1fr;
    gap: 7px;
}
.order_page__product__first__image {
    display: flex;
    height: 55px;
    justify-content: center;
    align-items: center;
}
.order_page__product__first__image img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}
.order_page__product__first__link {
    display: block;
    max-width: calc(100vw - 124px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    color: var(--darkgrey);
    font-weight: 700;
    line-height: 19px;
    margin-bottom: 6px;
}
.order_page__product__first__info {
    display: grid;
    grid-template-columns: 18px 45px 1fr 40px 23px;
    gap: 10px;
    line-height: 22px;
}
.order_page__product__first__info__discount {
    display: inline-block;
    /* vertical-align: middle; */
    width: 100%;
    background: var(--monolithyellow);
    color: #000;
    font-size: 16px;
    text-align: center;
    font-weight: 700;
    border-radius: 5px;
}
.order_page__product__first__info__qtyincart {
    color: #FFF;
    background: var(--darkgrey);
    text-align: center;
    border-radius: 5px;
}
.order_page__product__first__info__button {
    position: relative;
}
.order_page__product__first__info__button::after {
    content: '';
    position: absolute;
    top: 4px;
    left: 7px;
    width: 10px;
    height: 10px;
    color: #999;
    border-bottom: 1px solid;
    border-right: 1px solid;
    transform: rotate(45deg);
}

.order_page__product__second {
    display: grid;
    grid-template-columns: 1fr 1fr 62px;
    font-size: 12px;
    line-height: var(--lineheight);
    color: #999;
    border-top: 1px solid #d9d9d9;
    padding: 17px 15px 14px 22px;
    display: none;
}
.order_page__product.active .order_page__product__second {
    display: grid;
}
.order_page__product__second__qtyincart {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    color: #FFF;
    background: var(--darkgrey);
    border-radius: 5px;
    font-size: 14px;
    line-height: 40px;
    text-align: center;
}

body.fixed_bottom .orders_content {
    padding-bottom: 152px;
}
.order_page__fixed {
    background: #FFF;
    box-shadow: 0 -2px 12px rgba(0, 0, 0, 0.1);
}
body.fixed_bottom .order_page__fixed {
    position: fixed;
    bottom: 84px;
    left: 0;
    right: 0;
    z-index: 100;
}
.order_page__fixed__white {
    padding: 6px 23px 9px 23px;
    display: grid;
    grid-template-columns: 2fr 2fr 3fr;
    font-size: 12px;
    line-height: var(--lineheight);
}
.order_page__fixed__white__last {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    font-weight: 700;
    text-align: right;
}
.order_page__fixed__white__last strong {
    display: block;
    font-size: 20px;
    line-height: var(--lineheight);
}
.order_page__fixed__grey {
    padding: 14px 23px 12px 23px;
    display: grid;
    grid-template-columns: 45px 1fr;
    grid-template-rows: 45px;
    gap: 18px;
    background: #f8f8f8;
}
.sorting__products_button {
    background: #f2f2f2;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
}
.sorting__products_button svg path,
.sorting__products_button  svg line {
    stroke: #999;
}
.wrapper_loading_dots {
    display: flex;
    align-items: center;
    height: 28px;
}
.wrapper_loading_dots .loadingDot {
    background-color: var(--darkgrey);
}
/* oreder */
.debts_content {
    padding: 0 23px;
}

.debts_list__item {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 16px;
    color: #999;
}
.debts_list__item:nth-child(even) {
    background: #f2f2f2;
}
.debts_list__item:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
.debts_list__item__first {
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto;
    padding: 8px 46px 8px 21px;
}
.debts_list__item__first strong {
    color: var(--darkgrey);
    font-size: 14px;
    line-height: 26px;
}
.debts_list__item__first span {
    line-height: 24px;
}
.debts_list__days_overdue, .debts_list__days {
    padding: 0 10px;
    background: var(--maincolor);
    border-radius: 5px;
    color: #FFF;
}
.debts_list__days {
    background: var(--monolithgreen);
}
.debts_list__item__first__button {
    position: absolute;
    width: 32px;
    height: 32px;
    right: 8px;
    top: 19px;
}
.debts_list__item__first__button::after {
    content: '';
    position: absolute;
    top: 10px;
    left: 12px;
    width: 10px;
    height: 10px;
    color: #999;
    border-bottom: 1px solid;
    border-right: 1px solid;
    transform: rotate(45deg);
}
.debts_list__item.active .debts_list__item__first__button::after {
    transform: translateY(6px) rotate(225deg);
}

.debts_list__item__second {
    display: none;
    border-top: 1px solid #d9d9d9;
    padding: 16px 12px 13px 12px;
}
.debts_list__item.active .debts_list__item__second {
    display: block;
}
.debts_list__item__second__info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 9px;
    padding: 0 9px;
    margin-bottom: 20px;
}
.debts_list__item__second__buttons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 7px;
}
.debts_list__item__second__button {
    background: #f2f2f2;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    color: var(--darkgrey);
    text-align: center;
    padding: 7px;
}
.debts_list__item__second__button svg {
    display: block;
    margin: 0 auto 5px auto;
}

.debts__total_overdue {
    display: flex;
    justify-content: space-between;
    color: #FFF;
    background: var(--maincolor);
    line-height: 26px;
    font-weight: 700;
    border-radius: 5px;
    padding: 12px 21px;
    margin-bottom: 30px;
}
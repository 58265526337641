.account_content {
    padding: 0 23px;
}
.account_hello {
    line-height: 26px;
    font-weight: 600;
    margin-bottom: 20px;
}
.account_under_hello {
    margin-bottom: 20px;
}

.account_grid {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-bottom: 20px;
}
.account_grid a,
.account_grid button {
    width: 100%;
    position: relative;
    display: block;
    height: 124px;
    background: #F8F8F8;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    color: var(--darkgrey);
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    padding: 24px 15px 0 15px;
}
.account_grid a svg,
.account_grid button svg {
    display: block;
    margin: 0 auto 10px auto;
} 
.account_grid a .alert,
.account_grid button .alert {
    display: block;
    padding: 0;
    position: absolute;
    top: 20px;
    left: calc(50% + 7px);
    width: 24px;
    line-height: 24px;
    font-size: 12px;
    text-align: center;
    color: #FFF;
    background: var(--maincolor);
    border-radius: 50%;
    font-weight: 400;
}
.account_lang {
    display: block;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    margin: 0 auto 10px auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.selected_lang {
    display: block;
    font-size: 12px;
    font-weight: 400;
}

.account__lang_selector__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    visibility: hidden;
}
.account__lang_selector__wrapper.active {
    visibility: visible;
}
.account__lang_selector__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,.5);
    transition: var(--transition);
    opacity: 0;
}
.account__lang_selector__wrapper.active .account__lang_selector__overlay {
    opacity: 1;
}
.account__lang_selector__wrapper .lang_selector__list {
    display: block;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) scale(0);
    transition: var(--transition);
}
.account__lang_selector__wrapper.active .lang_selector__list {
    transform: translateX(-50%) translateY(-50%) scale(1);
}
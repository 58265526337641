.alert_popup__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999999999;
    visibility: hidden;
}
.alert_popup__wrapper.active {
    visibility: visible;
}
.alert_popup__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,.5);
    z-index: 1;
    transition: var(--transition);
    opacity: 0;
}
.alert_popup__wrapper.active .alert_popup__overlay {
    opacity: 1;
}
.alert_popup__content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: calc(50%);
    /* min-width: 300px; */
    min-width: calc(100vw - 100px);
    background: #FFF;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    padding: 50px 40px 40px 40px;
    text-align: center;
    transform: translate(-50%, -50%) scale(0);
    transition: var(--transition);
    z-index: 2;
}
.alert_popup__content_padding_50_10 {
    padding: 60px 10px 30px 10px;
}
.alert_popup__wrapper.active .alert_popup__content {
    transform: translate(-50%, -50%) scale(1);
}
.alert_popup__content__close {
    position: absolute;
    top: 19px;
    right: 22px;
    width: 21px;
    height: 21px;
    border: 1px solid #999;
    border-radius: 50%;
}
.alert_popup__content__close::before, .alert_popup__content__close::after {
    content: '';
    position: absolute;
    top: 4px;
    left: 9px;
    width: 1px;
    height: 11px;
    transform: rotate(45deg);
    background: #999;
}
.alert_popup__content__close::after {
    transform: rotate(-45deg);
}
.alert_popup__content__close:hover {
    border-color: var(--darkgrey);
}
.alert_popup__content__close:hover::before, .alert_popup__content__close:hover::after {
    background: var(--darkgrey);
}
.alert_popup__content__text {
    margin-bottom: 15px;
    max-height: calc(100vh - 210px);
    overflow-y: auto;
    text-align: justify;
}
.alert_popup__text_align_left{
    text-align: left;
}

@media screen and (min-width: 550px) {
    .alert_popup__content {
        display: block;
    }
    .alert_popup__content button{
        margin: 5px;
    }
}
.brands_content {
    padding: 0 23px;
}
.brands_bottom {
    padding-bottom: 160px;
}
.brands_search {
    position: fixed;
    bottom: 84px;
    left: 0;
    right: 0;
    background: #FFF;
}

.brands_search__form {
    position: relative;
    border-bottom: 1px solid #D9D9D9;
    z-index: 5;
    box-shadow: 0 -2px 12px rgba(0, 0, 0, 0.1);
    border-radius: 5px 5px 0 0;
}
.brands_search__form__input {
    width: 100%;
    border: 0 none;
    font-size: 16px;
    line-height: 52px;
    padding: 0 108px 0 23px;
    border-radius: 5px 5px 0 0;
    background-image: url("data:image/svg+xml,%3Csvg fill='none' height='16' viewBox='0 0 17 16' width='17' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%23606060' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'%3E%3Cpath d='m7.58808 13.7353c3.37872 0 6.11762-2.739 6.11762-6.11766 0-3.37868-2.7389-6.11764-6.11762-6.11764-3.37867 0-6.11762 2.73896-6.11762 6.11764 0 3.37866 2.73895 6.11766 6.11762 6.11766z'/%3E%3Cpath d='m15.9999 14.5001-3.3265-2.9569'/%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 80px center;
}
.brands_search__form__abc {
    position: absolute;
    top: 10px;
    right: 23px;
    line-height: 32px;
    letter-spacing: 4px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    color: var(--darkgrey);
    z-index: 2;
}
.brands_search__form__abc.active {
    color: var(--maincolor);
}
.brands_search__abc {
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
    background: #FFF;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 23px 23px;
    gap: 40px;
    font-weight: 600;
    font-size: 16px;
    border-radius: 5px 5px 0 0;
    display: flex;
    transition: var(--transition);
    transform: translateY(100%);
    z-index: 4;
}
.brands_search__abc.active {
    transform: translateY(0);
}
.brands_search__abc__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 3;
    transition: var(--transition);
    visibility: hidden;
    opacity: 0;
}
.brands_search__abc__overlay.active {
    visibility: visible;
    opacity: 1;
}
.brands_search__abc li[data-length="0"] {
    display: none;
}
.brands_search__abc a {
    color: var(--darkgrey);
}
.brands_search__abc a:active {
    color: var(--maincolor);
}
.brands__big_letter {
    font-size: 18px;
    line-height: var(--lineheight);
    font-weight: 700;
    padding: 30px 23px;
}
.brands__single_brand {
    border-top: 1px solid #d9d9d9;
}
.brands__single_brand__title {
    display: block;
    width: 100%;
    position: relative;
    line-height: 50px;
    padding: 0 40px 0 23px;
    font-weight: 700;
    color: var(--darkgrey);
    text-align: left;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.brands__single_brand__title span {
    pointer-events: none;
}
.brands__single_brand__title::after {
    content: '';
    position: absolute;
    top: 19px;
    right: 24px;
    width: 9px;
    height: 9px;
    border-bottom: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
}
.brands__single_brand.active .brands__single_brand__title {
    color: var(--maincolor);
}
.brands__single_brand.active .brands__single_brand__title::after {
    transform: translateY(3px) rotate(225deg);    
}
.brands__single_brand__info {
    display: none;
    padding: 12px 42px 24px 42px;
}
.brands__single_brand.active .brands__single_brand__info {
    display: block;
}
.brands__single_brand__info__text {
    margin-bottom: 18px;
}
.see_all_products_now {
    display: block;
    margin: 0 auto 31px auto;
}
.brands__single_brand__info__social {
    margin: 0 auto;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
}
.brands__single_brand__info__social a {
    margin: 0 20px;
}

.brands_animated__placeholder {
    padding: 0 23px 0 23px;
}
.content_placeholder__big_letter {
    width: 2em;
    height: 2em;
    margin: 2em 0;
}
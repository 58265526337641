.thankyou_content {
    padding: 0 23px;
}
.thankyou_red {
    padding: 20px 20px 24px 20px;
    margin-bottom: 23px;
    color: #FFF;
    background: var(--maincolor);
    border-radius: 5px;
}
.thankyou_red__header {
    font-size: 16px;
    line-height: var(--lineheight);
    font-weight: 700;
    margin-bottom: 14px;
}
.thankyou_red__text {
    margin-bottom: 14px;
}
.thankyou_red__button {
    color: #FFF;
    line-height: 26px;
    font-weight: 600;
}
.thankyou_red__button svg {
    position: relative;
    top: 3px;
    margin: 0 5px 0 0;
}

.other_account_options, .your_designated_support_team {
    font-size: 14px;
    line-height: 26px;
    font-weight: 700;
    margin-bottom: 20px;
}
.thankyou_account_buttons {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-bottom: 20px;
}
.thankyou_account_buttons a {
    position: relative;
    display: block;
    height: 124px;
    background: #F8F8F8;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    color: var(--darkgrey);
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    padding: 31px 10px 0 10px;
}
.thankyou_account_buttons a svg {
    display: block;
    margin: 0 auto 10px auto;
}
.thankyou_account_buttons a .alert {
    display: block;
    padding: 0;
    position: absolute;
    top: 27px;
    left: calc(50% + 7px);
    width: 24px;
    line-height: 24px;
    font-size: 14px;
    text-align: center;
    color: #FFF;
    background: var(--maincolor);
    border-radius: 50%;
    font-weight: 400;
}
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/4.1.5/css/flag-icons.min.css');

.login__wrapper {
    padding-top: 25px;
    background: #f8f8f8;
}
.login__header {
    display: block;
    padding: 0 var(--sidagap) 17px var(--sidagap);
}
.login__header img,
.login__header svg {
    display: block;
    height: 44px;
    width: auto;
}
.login__content {
    padding: 0 var(--sidagap);
}
.login__header__title {
    font-size: 1.42875rem;
    line-height: var(--lineheight);
    font-weight: 700;
    margin-bottom: 10px;
}
.privacy_policy__disclosures {
    list-style: none;
    display: flex;
    margin: 10px 0 10px -10px;
}
.privacy_policy__disclosures li {
    position: relative;
    margin: 0 10px;
}
.privacy_policy__disclosures li:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 3px;
    right: -10px;
    bottom: 3px;
    width: 1px;
    background: #999;
}
.login__banner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
}
.login__banner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.country_input {
    /* display: none; */
    position: absolute !important;
    top: 5px;
    left: 5px;
    right: auto;
    width: 40px !important;
}
.country_input input.form-control {
    /* display: none !important; */
    visibility: hidden;
    pointer-events: none;
}
.phone_input {
    padding-left: 99px;
}
.phone_code {
    position: absolute;
    top: 15px;
    left: 53px;
    width: 44px;
    font-size: 16px;
    line-height: 1em;
    color: #999;
    text-align: right;
    pointer-events: none;
}

.dont_have_an_account,
.email_input,
.code_input,
.email_submit,
.code_submit,
.prefer_login_by_email,
.prefer_login_by_sms,
.forgot_your_email
{
    position: relative;
    margin-bottom: 10px;
}
.lang_selector {
    width: 140px;
    position: relative;
    margin-bottom: 20px;
}
.lang_selector__list {
    display: none;
    list-style: none;
    position: absolute;
    top: -2px;
    left: -21px;
    width: 200px;
    background: #f8f8f8;
    border: 1px solid #D9D9D9;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
}
.lang_selector.active .lang_selector__list {
    display: block;
}
.lang_selector__list li {
    padding: 0 20px;
    line-height: 45px;
    cursor: pointer;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
}
.lang_selector__list li:first-child {
    border-radius: 5px 5px 0 0;
}
.lang_selector__list li:last-child {
    border-radius: 0 0 5px 5px;
}
.lang_selector__list li:hover,
.lang_selector__list li.current {
    background: #f2f2f2;
    border-top: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;
}
.lang_selector__current {
    position: relative;
    line-height: 45px;
    cursor: pointer;
}
.lang_selector__current::after {
    content: '';
    position: absolute;
    top: 19px;
    right: 10px;
    width: 7px;
    height: 7px;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    transform: rotate(45deg);
    transition: var(--transition);
}
.lang_selector__flag {
    display: inline-block;
    position: relative;
    top: 4px;
    width: 18px;
    height: 18px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    margin-right: 15px;
}
.please_select_account {
    line-height: var(--lineheight);
    margin-bottom: 10px;
}

.select_account__submit {
    margin-bottom: 116px;
}

.login__select_account {
    position: relative;
    line-height: 44px;
    margin-bottom: 10px;
    color: #999;
}
.login__select_account__current {
    position: relative;
    background: #FFF;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    padding: 0 15px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    height: 40px;
}
.login__select_account .clear-button {
    position: absolute;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 22px;
    color: #999;
    display: none;
  }
  
  .login__select_account input[type="text"]:focus ~ .clear-button {
    display: inline-block;
  }
  
  .login__select_account input[type="text"]:hover ~ .clear-button {
    color: #ccc;
  }
  
  .login__select_account input[type="text"] + .clear-button {
    margin-right: 30px;
  }
  
.login__select_account.active .login__select_account__current {
    border-radius: 5px 5px 0 0;
}
.login__select_account__current::after {
    content: '';
    position: absolute;
    top: 16px;
    right: 20px;
    width: 8px;
    height: 8px;
    color: var(--maincolor);
    border-bottom: 1px solid;
    border-right: 1px solid;
    transform: rotate(45deg);
}
.login__select_account__list {
    display: none;
    list-style: none;
    cursor: pointer;
    position: absolute;
    top: calc(100% - 1px);
    left: 0;
    right: 0;
    background: #FFF;
    border: 1px solid #d9d9d9;
    border-radius: 0 0 5px 5px;
    z-index: 10;
}
.login__select_account.active .login__select_account__list {
    display: block;
    min-height: min-content;
    max-height: calc(100vh - 180px);
    overflow-y: auto;
}
.login__select_account__list li {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 15px;
}
.login__select_account__list li:hover {
    color: var(--maincolor);
    background: #f8f8f8;
}
.login__select_account__list li.current {
    color: var(--maincolor);
}

.six_digits {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 10px;
}
.six_digits__single {
    width: 41px;
    background: #FFF;
    border: 1px solid #999;
    border-radius: 5px;
    text-align: center;
    line-height: 43px;
    font-size: 1.25rem;
    font-weight: 600;
    margin-right: 10px;
}
.six_digits__dash {
    width: 8px;
    height: 2px;
    background: #999;
    margin-right: 10px;
}
.code_input {
    display: none;
}